
import Webfooter from '../../components/web/footer';
import Container from '@mui/material/Container';
import { Grid } from '@mui/material'
import Card from '@mui/material/Card';
import { useState, useEffect } from 'react';
import '../../components/SCSS/style.scss';
import { useLocation } from 'react-router-dom';
// import LoadingScreen from '../../components/web/loading';

export default function Home() {
  const [show_type, setShowType] = useState(1);
  const { state } = useLocation();
  const menu = state && state.menu

  useEffect(() => {
    const page = menu || 1
    setShowType(page)
  }, [menu]);

  function importAll(r) {
    const images = {};
    // Iterate over the keys in the context and map them to the images object
    r.keys().forEach((item) => {
      // console.log('ter', item)
      const key = item.replace('./', ''); // Remove the './' prefix
      const value = r(item); // Get the image using r(item)
      images[key] = value;
    });

    return images;
  }

  const images = importAll(require.context('./../../images/menu'));

  const handleFooterChange = (footerValue) => {
    if (footerValue === 1) {
      setShowType(2);
    }
    else if (footerValue === 0) {
      setShowType(1);
    }
    else if (footerValue === 2) {
      setShowType(3);
    }
  }
  const handleClick = (uri) => {
    if(uri !== '/ricehere'){
      window.location.href = uri
    }
     
  }

  const menus = (
    [
      {
        name: 'คำแนะนำในการปลูกข้าว',
        desc: 'รวบรวมคำแนะนำในการปลูกข้าว',
        icon: '',
        uri: '',
        hidden: true,
        type: 1 // 1 : ธรรมดา , 2 : พยากรณ์
      },
      {
        name: 'องค์ความรู้เรื่องข้าว',
        desc: 'รวบรวมองค์ความรู้เรื่องข้าว',
        icon: <img src={images['ic2-01.png']} alt='' style={{ width: '20vw', marginRight: '15px' }} />,
        uri: '/knowledge_rice',
        hidden: false,
        type: 1
      },
      {
        name: 'พยากรณ์ราคาข้าวเปลือก',
        desc: 'ระบบพยากรณ์ราคาข้าวเปลือกตามช่วงเดือน',
        icon: <img src={images['ic3-01-01.png']} alt='' style={{ width: '20vw', marginRight: '15px' }} />,
        uri: '/forecastprice',
        hidden: false,
        type: 2
      },
      {
        name: 'ติดตามสถานการณ์ภัยแล้ง',
        desc: 'ระบบติตตามสถาณการณ์ภัยแล้ง',
        icon: <img src={images['ic13-01.png']} alt='' style={{ width: '20vw', marginRight: '15px' }} />,
        uri: '/drought_disaster',
        hidden: false,
        type: 1
      },
      {
        name: 'รวมแอพพลิเคชัน',
        desc: 'แหล่งรวบรวมแอพพลิเคชั่น',
        icon: '',
        uri: '',
        hidden: true,
        type: 1
      },
      {
        name: 'ปริมาณน้ำในอ่างเก็บน้ำ',
        desc: 'รายงานปริมาณอ่างเก็บน้ำในแต่ละท้องที่',
        icon: <img src={images['w9-01.png']} alt='' style={{ width: '20vw', marginRight: '15px' }} />,
        uri: '/irrigation',
        hidden: false,
        type: 1
      },
      {
        name: 'ข้อมูลชุดดิน',
        desc: 'รายงานผลและข้อมูลจากชุดดินตัวอย่าง',
        icon: <img src={images['ic12-01.png']} alt='' style={{ width: '20vw', marginRight: '15px' }} />,
        uri: '/soilinfo',
        hidden: false,
        type: 1
      },
      {
        name: 'ราคาข้าวจากกรมการค้าภายใน',
        desc: 'รายงานราคาข้าวจากกรมการค้าภายใน',
        icon: <img src={images['ic3-01-01.png']} alt='' style={{ width: '20vw', marginRight: '15px' }} />,
        uri: '/pricerice',
        hidden: false,
        type: 1
      },
      {
        name: 'บริการข้อมูล',
        desc: 'แหล่งรวบรวมบริการด้านข้อมูล',
        icon: '',
        uri: '',
        hidden: true,
        type: 1
      },
      {
        name: (
          <div>
            พยากรณ์โรคไหม้<br />และเพลี้ยกระโดดสีน้ำตาล
          </div>
        ),
        desc: (
          <div>
            ติดตามและพยากรณ์โรคไหม้<br />และเพลี้ยกระโดดสีน้ำตาล
          </div>
        ),
        icon: <img src={images['ic14-01.png']} alt='' style={{ width: '20vw', marginRight: '15px' }} />,
        uri: '/bug',
        hidden: false,
        type: 2
      },
      {
        name: 'พยากรณ์อากาศ',
        icon: <img src={images['w1-01.png']} alt='' style={{ width: '20vw', marginRight: '15px' }} />,
        desc: 'ติดตามและพยากรณ์อากาศ',
        uri: '/weather',
        hidden: false,
        type: 2
      },
      {
        name: 'พยากรณ์น้ำ',
        desc: 'ติดตามและพยากรณ์น้ำ',
        icon: <img src={images['w16-0101-01.png']} alt='' style={{ width: '20vw', marginRight: '15px' }} />,
        uri: '/forecastirrigation',
        hidden: false,
        type: 2
      },
      {
        name: 'จับคู่เจรจาสินค้าข้าว',
        desc: 'ตลาดจับคู่ธุรกิจสินค้าข้าวแบบออนไลน์',
        icon: <img src={images['ic-bm-01.png']} alt='' style={{ width: '20vw', marginRight: '15px' }} />,
        uri: '/matching',
        hidden: false,
        type: 3
      },
      {
        name: 'RiceHere Application',
        desc: 'พบกับ RiceHere Application เร็วๆนี้',
        icon: <img src={images['w15-01-01.png']} alt='' style={{ width: '20vw', marginRight: '15px' }} />,
        uri: '/ricehere',
        hidden: false,
        type: 3
      }

    ]
  )

  return (
    <div className="background">
      <div className="background2">
        <div style={{ marginTop: '8px', textAlign: 'center', fontWeight: '500', fontSize: '20px' }}>
          <div className='image-container'>
          </div>
          <h4>ALLRICE ที่ปรึกษาชาวนาไทย</h4>
        </div>
      </div>

      <Container component="section">
        <Grid container spacing={0} sx={{ mt: 2, mb: 4 }}>
          {
            menus.map((menu) => {
              return (menu.hidden !== true && menu.type === show_type && (
                <Grid item xs={12} key={menu.name}>
                  {/* <Card className='menu-card' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} onClick={() => window.location.href = menu.uri}>*/}
                     <Card className='menu-card' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} onClick={() => handleClick(menu.uri)}>
                    <div>
                      <div className="icon-overlay">
                        {menu.icon}
                      </div>
                    </div>
                    <div>
                      <div className='menu-content' style={{ textAlign: 'right' }}>
                        <span className="menu-header">{menu.name}</span>
                        <span className="menu-description">{menu.desc}</span>
                      </div>
                    </div>
                  </Card>
                </Grid>
              ))
            })
          }
        </Grid>
      </Container>
      <Webfooter onValueChange={handleFooterChange} />
    </div>
  )
}