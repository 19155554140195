import { Typography } from '@mui/material'
import React from 'react'

const Desc = () => {
    return (
        <div>
            <Typography gutterBottom sx={{ fontSize: '14px', mb: 5 }}>
                ชื่อโรคข้าว: โรคถอดฝักดาบ<br />
                การกัดทำลาย: ในระยะกล้าถ้าอาการรุนแรง ถ้าอาการรุนแรงต้นกล้าจะแห้งตาย แต่มักพบกับข้าวอายุเกิน 15 วัน<br />
                การป้องกัน<br />
                "1.หลีกเลี่ยงการนำเมล็ดพันธุ์จากแหล่งที่เคยเป็นโรคระบาดมาปลูก
            </Typography>

            <Typography gutterBottom sx={{ fontSize: '14px', mb: 5 }}>
                ชื่อโรคข้าว: โรคใบหงิก (โรคจู๋)<br />
                การกัดทำลาย: ต้นข้าวเป็นโรคได้ ทั้ง ระยะกล้า แตกกอ ตั้งท้อง อาการของต้นข้าวที่เป็นโรค สังเกตได้ง่าย คือ ข้าวต้นเตี้ยกว่าปกติ ใบแคบและสั้นสีเขียวเข้ม แตกใบใหม่ช้ากว่าปกติ แผ่นใบไม่สมบูรณ์ ปลายใบบิดเป็นเกลียว ขอบใบแหว่งวิ่นและเส้นใบบวมโป่งเป็นแนวยาวทั้งที่ใบและกาบใบ ข้าวที่เป็นโรคออกรวงล่าช้าและให้รวงไม่สมบูรณ์ เมล็ดลีบ ผลผลิตลดลง 30 ถึง 70 เปอร์เซ็นต์ และข้าวพันธุ์อ่อนแอที่เป็นโรคในระยะกล้า ต้นข้าวอาจตายและไม่ได้ผลผลิตเลย<br />
                การป้องกัน<br />
                "1. กำจัดหรือทำลายเชื้อไวรัส โดยไถกลบหรือเผาตอซังในนาที่มีโรค กำจัดวัชพืช โดยเฉพาะวัชพืชใกล้แหล่งน้ำซึ่งเป็นที่อยู่อาศัยและขยายพันธุ์ของแมลงพาหะ<br />
                2. ใช้พันธุ์ที่ต้านทานต่อเพลี้ยกระโดดสีน้ำตาล เช่น พันธุ์สุพรรณบุรี 90 สุพรรณบุรี 3 และชัยนาท 2 แต่ไม่ควรปลูกข้าวพันธุ์ดังกล่าว ติดต่อกันเป็นแปลงขนาดใหญ่ เนื่องจากแมลงสามารถปรับตัว เข้าทำลายพันธุ์ข้าวที่ต้านทานได้<br />
                3. ใช้สารป้องกันกำจัดแมลงพาหะ ได้แก่ ใช้สารฆ่าแมลงในระยะที่แมลงเป็นตัวอ่อน เช่น ไดโนทีฟูเรน หรือ บูโพรเฟซิน หรือ อีโทเฟนพรอกซ์ ไม่ควรใช้สารฆ่าแมลงผสมกันหลายๆ ชนิดหรือใช้สารฆ่าแมลงผสมสารป้องกันกำจัดโรคพืชหรือสารกำจัดวัชพืช เพราะอาจทำให้ประสิทธิภาพของสารฆ่าแมลงลดลง<br />
            </Typography>

            <Typography gutterBottom sx={{ fontSize: '14px', mb: 5 }}>
                ชื่อศัตรูข้าว: แมลงบั่ว<br />
                รูปร่างลักษณะ: ตัว เต็มวัยของแมลงบั่ว มีลักษณะคล้ายยุงหรือริ้นลำตัวยาวประมาณ3-4มิลลิเมตรหนวด และขามีสีดำ เวลากลางวันตัวเต็มวัย จะเกาะซ่อนตัวอยู่ใต้ใบข้าวบริเวณกอข้าวและจะบินไปหาที่มีแสงไฟเพื่อผสม พันธุ์เพศผู้มีลำตัวสีน้ำตาลปนเหลืองขนาดเล็กกว่าเพศเมียเพศเมียส่วนท้องมี สีแดงส้ม<br />
                การขยายพันธุ์: วางไข่ใต้ใบข้าวเป็นส่วนใหญ่ในตอนกลางคืนโดยวางเป็นฟองเดี่ยวๆ หรือเป็นกลุ่ม 3-4 ฟอง เพศเมีย 1 ตัวสามารถวางไข่ได้หลายร้อยฟองในชั่ว อายุ 4 วัน ไข่มีลักษณะคล้ายกล้วยหอม สีชมพูอ่อน ยาวประมาณ 0.45 มิลลิเมตร กว้าง 0.09มิลลิเมตร ระยะไข่ประมาณ 3-4 วัน ไข่ต้องการความชื้นสัมพัทธ์ 80-90 % ในการฟักออกเป็นตัวหนอน<br />
                การกัดทำลาย: ตัวเต็มวัยแมลงบั่วจะเข้าแปลงนาตั้งแต่ช่วงระยะเวลา 25-30 วัน เข้าทำลายยอดที่กำลังเจริญทำให้เกิดเป็นหลอดลักษณะคล้ายหลอดหอม หลอดจะปรากฏภายใน 1 สัปดาห์ หลังจากที่ตัวหนอนเข้าไปที่จุดเจริญของข้าว ต้นข้าวและกอข้าวที่ถูกทำลายจะมีอาการแคระแกร็น เตี้ย ลำต้นกลม มีสีเขียวเข้ม ยอดที่ถูกทำลายไม่สามารถออกรวงได้ ทำให้ผลผลิตข้าวลดลงมาก ระยะข้าวแตกกอจะเป็นระยะที่บั่วเข้าทำลายมาก เมื่อข้าวเกิดช่อดอก (primodia) แล้วจะไม่ถูกหนอนบั่ว พบระบาดมากในช่วงฤดูฝน ในสภาพที่มีเมฆมากหรือมีฝนตก<br />
                การป้องกัน<br />
                1. ขจัดวัชพืชรอบแปลงนา เช่น ข้าวป่า หญ้าข้าวนก หญ้าไซ หญ้าแดง หญ้าชันกาด และหญ้านกสีชมพู ก่อนตกกล้าหรือหว่านข้าวเพื่อทำลายพืชอาศัยของแมลงบั่ว<br />
                2. ภาคเหนือ ควรปลูกข้าวหรือปักดำช่วงวันที่ 15 กรกฎาคม - สิงหาคม หรือปรับวิธีปลูกโดยการปักดำ 2 ครั้ง เพื่อลดความรุนแรงที่เกิดจากการทำลายของแมลงบั่ว หลังปักดำจนถึงข้าวอายุ 45 วัน ส่วน ภาคตะวันออกเฉียงเหนือ ควรปลูกหรือปักดำระหว่างเดือนมิถุนายน – 15 กรกฎาคม<br />
                3. ไม่ควรปลูกข้าวโดยวิธีหว่านหรือปักดำถี่ (ระยะปักดำ 10x15 และ 15x15 เซนติเมตร) ในพื้นที่ภาคเหนือ และภาคตะวันออกเฉียงเหนือ ที่มีการระบาดของแมลงบั่วเป็นประจำ<br />
                4. ทำลายตัวเต็มวัยที่บินมาเล่นแสงไฟตามบ้านช่วงเวลาตั้งแต่ 19:00-21:00 น. โดยใช้ไม้ตีแมลงวัน<br />
                5. ไม่ควรใช้สารฆ่าแมลงใดๆ ในการป้องกันกำจัดแมลงบั่วเนื่องจากไม่ได้ผลและยังทำลายศัตรูธรรมชาติ<br />

            </Typography>

            <Typography gutterBottom sx={{ fontSize: '14px', mb: 5 }}>
                ชื่อศัตรูข้าว: หนอนกระทู้คอรวง<br />
                รูปร่างลักษณะ: หนอนที่ฟักออกใหม่กัดกินใบหญ้าอ่อนจนอายุประมาณ 15 วัน จึงเริ่มกัดกินใบและรวงข้าว ระยะหนอนประมาณ 25-30 วัน หนอนมีขนาดค่อนข้างใหญ่กว้างประมาณ 3.5-4 มิลลิเมตร ยาวประมาณ 2.8 มิลลิเมตร ลำตัวสีน้ำตาลอ่อน หนอนเข้าดักแด้ที่โคนกอข้าวหรือตามรอแตกของดิน ดักแด้มีสีน้ำตาลแดง ระยะดักแด้ 10-12 วัน<br />
                การขยายพันธุ์: วางไข่เป็นกลุ่มตามกาบใบและลำต้นหรือฐานของใบที่ม้วน ไข่ไม่มีขนปกคลุม วางไข่เป็นกลุ่มๆละประมาณ 100 ฟอง ระยะไข่นาน 6-8 วัน<br />
                การกัดทำลาย: หนอนกระทู้คอรวงชอบกัดกินส่วนคอรวงหรือระแง้ของรวงข้าวที่กำลังจะ สุก(rippening stage) ทำให้คอรวงขาด สามารถทำลายรวงข้าวได้มาถึง 80% โดยลักษณะการทำลายคล้ายหนอนกระทู้กล้า มักเข้าทำลายต้นข้าวช่วงกลางคืนหรือตอนพลบค่ำถึงเช้าตรู่ กลางวันอาศัยตามใบหรือโคนต้นข้าวหรือวัชพืชตระกูลหญ้า หนอนจะกัดกินต้นข้าวทุกวันจนกระทั่งเข้าดักแด้ พบระบาดมากหลังน้ำท่วมหรือฝนตกหนักหลังผ่านช่วงแล้งที่ยาวนานแล้วตามด้วยฝน ตกหนัก การทำลายจะเสียหายรุนแรง จนชาวนาเรียกกันว่า “ หนอนกระทู้ควายพระอินทร์”<br />
                การป้องกัน<br />
                1. กำจัดวัชพืชรอบๆ แปลงนา<br />
                2. เมื่อมีการระบาดรุนแรง หากตรวจนับพบใบข้าวถูกทำลายกอหรือจุดละ 5 กอหรือ 5 รวงจากข้าว 20 กอหรือจุดสุ่มนับ ให้ใช้สารตามคำแนะนำ<br />
            </Typography>

            <Typography gutterBottom sx={{ fontSize: '14px', mb: 5 }}>
                ชื่อศัตรูข้าว: แมลงสิง<br />
                รูปร่างลักษณะ: ตัวเต็มวัยมีรูปร่างเพรียวยาวประมาณ 15 มิลลิเมตร หนวดยาวใกล้เคียงกับลำตัว ลำตัวด้านบนสีน้ำตาล ลำตัวด้านล่างสีเขียว เมื่อถูกรบกวนจะบินหนี และปล่อยกลิ่นเหม็นออกจากต่อมที่ส่วนท้อง<br />
                การขยายพันธุ์: เพศเมียวางไข่ได้หลายร้อยฟองในช่วงชีวิตประมาณ 2-3 เดือน วางไข่เป็นกลุ่มมี 10-12 ฟอง เรียงเป็นแถวตรงบนใบข้าวขนานกับเส้นกลางใบ ไข่มีสีน้ำตาลแดงเข้ม รูปร่างคล้ายจาน ระยะไข่นาน 7 วัน ตัวอ่อนมีสีเขียวแกมน้ำตาลอยู่รวมกันเป็นกลุ่ม และดูดกินน้ำเลี้ยงจากกาบใบข้าวก่อน ต่อมาเป็นตัวเต็มวัยจะเข้าทำลายเมล็ดข้าวในระยะข้าวเป็นน้ำนมจนถึงออกรวง ตัวอ่อนมี 5 ระยะ<br />
                การกัดทำลาย: ตัวอ่อนและตัวเต็มวัยใช้ปากแทงดูดกินน้ำเลี้ยงจากเมล็ดข้าว ระยะเป็นน้ำนม แต่ก็สามารถดูดกินเมล็ดข้าวทั้งเมล็ดอ่อนและเมล็ดแข็งโดยตัวเต็มวัยจะทำความเสียหายมากกว่า เพราะดูดกินเป็นเวลานานกว่าทำให้เมล็ดลีบ หรือเมล็ดไม่สมบูรณ์และผลผลิตข้าวลดลงการดูดกินของแมลงสิงไม่ทำให้เป็นรูบนเปลือกของเมล็ดเหมือนมวนชนิดอื่นโดยปากจะเจาะผ่านช่องว่างระหว่างเปลือกเล็กและเปลือกใหญ่ของเมล็ดข้าว ความเสียหายจากการ ทำลายของแมลงสิงทำให้ข้าวเสียคุณภาพมากกว่าทำให้น้ำหนักเมล็ดลดลง<br />
                การป้องกัน<br />
                1. กำจัดวัชพืชในนาข้าว คันนาและรอบๆแปลง<br />
                2.ใช้สวิงโฉบจับตัวอ่อนและตัวเต็มวัยในนาข้าวที่พบระบาดและนำมาทำลาย<br />
                3. ตัวเต็มวัยชอบกินเนื้อเน่า นำเนื้อเน่าแขวนไว้ตามนาข้าว และจับมาทำลาย<br />
                4. หลีกเลี่ยงการปลูกข้าวต่อเนื่องเพื่อลดการแพร่ขยายพันธุ์<br />
                5. ใช้สารฆ่าแมลง คาร์โบซัลแฟน (พอสซ์ 20% อีซี) อัตรา 80 มิลลิลิตรต่อน้ำ 20 ลิตร พ่นเมื่อแมลงสิงมากกว่า 4 ตัวต่อตารางเมตร ในระยะข้าวเป็นน้ำนม<br />
            </Typography>

            <Typography gutterBottom sx={{ fontSize: '14px', mb: 5 }}>
                ชื่อศัตรูข้าว: ﻿เพลี้ยไฟ<br />
                รูปร่างลักษณะ: เพลี้ยไฟเป็นแมลงขนาดเล็กยาวประมาณ 1-2 ม.ม มีทั้งมีปีกและไม่มีปีก ตัวอ่อนทีสีเหลืองนวล ตัวแก่มีสีดำ การสังเกตอย่างๆ โดยใช้มือจุ่มน้ำแล้วลูบผ่านไปตามใบข้าวจะติดมือขึ้นมาเห็นได้ชัด ทั้งตัวเต็มวัยและตัวอ่อนดูดกินน้ำเลี้ยงจากใบข้าว โดยซ่อนอยู่ภายในใบที่ม้วนอยู่หรือใต้กาบใบหรือตามดอกข้าว<br />
                การขยายพันธุ์: ไข่ของเพลี้ยไฟจะถูกวางในเนื้อเหยื่อของใบข้าว หลังจากฟักออกเป็นตัวอ่อนแล้วจะดูดกินน้ำเลี้ยงภายในใบอ่อนที่ยังม้วนอยู่ใกล้ๆ ปลายใบหรือบริเวณโคนใบใกล้ๆ กาบใบ การเจริญจากตัวอ่อนจนกระทั่งตัวเต็มวัย ใช้เวลาประมาณ 15 วัน<br />
                การกัดทำลาย: เพลี้ยไฟทำลายต้นข้าวโดยการดูดกินน้ำเลี้ยงจากใบข้าว ข้าวที่ถูกทำลายจะแสดงอาการแห้งที่ปลายใบก่อน กลางๆ ใบจะซีด ใบจะม้วนจากขอบใบมากลางใบ ถ้าระบาดรุนแรงจะทำให้ข้าวแห้งตายทั้งแปลงได้ มักระบาดรุนแรงในภาคตะวันอกเฉียงเหนือระหว่างเดือนพฤษภาคม-กรกฎาคม ซึ่งเป็นระยะที่ชาวนาทำการตกกล้า ในภาคกลางมักระบาดในนาปรังในระยะต้นฤดูคือระหว่างเดือนมกราคม-เมษายน การระบาดจะรุนแรงมากในช่วงที่อากาศแห้งแล้ง ฝนไม่ตก และแปลงนาขาดน้ำหล่อเลี้ยงต้นกล้า (จะเห็นได้ชัดในนาหว่านน้ำตม)<br />
                การป้องกัน<br />
                1. ในแปลงกล้าซึ่งควบคุมน้ำได้ เมื่อพบว่ามีแมลเข้าทำลาย ซึ่งอาจจะดูได้จากรอยซีดขาวบนใบหรือใช้มือจุ่มน้ำแล้วลูบไปมาบนใบข้าว จะเห็นมีแมลงเพลี้ยไฟตัวเล็กๆ ติดมือมา ให้ไข่น้ำท่วมยอดข้าว 1-2 วัน จึงเอาน้ำออก ใส่ปุ๋ยไนโตรเจนเร่งการเจริญเติบโต<br />
                2.ในแปลงข้าวซึ่งไม่สามารถควบคุมน้ำได้ ให้ใช้สารฆ่าแมลงฉีดพ่นเมื่อเริ่มพบมีการทำลาย<br />
                - มาลาไทออน (83% อีซี) อัตรา 20 มิลลิลิตรต่อน้ำ 20 ลิตร<br />
                - คาร์บาริล (85% ดับบลิวพี) อัตรา 20 มิลลิลิตรต่อน้ำ 20 ลิตร พ่นสารเมื่อพใบม้วน 20%<br />
            </Typography>

            <Typography gutterBottom sx={{ fontSize: '14px', mb: 5 }}>
                ชื่อศัตรูข้าว: เพลี้ยกระโดดสีน้ำตาล<br />
                รูปร่างลักษณะ: ตัวเต็มไวมีขนาดยาวประมาณ 3 ม.ม. กว้าง 1 ม.ม. มีสีน้ำตาลหรือน้ำตาลเทา หนวดอยู่ด้านข้างขอหัวและตั้งอยู่ใต้ตา ตัวเต็มวันมีรูปร่า 2 แบบ คือ เป็นได้ทั้งชนิดปีกยาวและชนิดปีกสั้น สามารถเคลื่อนย้ายได้รวดเร็วจากแปลงนาหนึ่งไปอีกแปลงนาหนึ่โดยกระแสลม<br />
                การขยายพันธุ์: เพลี้ยกระโดดสีน้ำตาลจะวางไข่ที่บริเวณกาบใบหรือเส้นกลางใบข้าว ไข่จะวางเป็นกลุ่มเรียกันเป็นแถวลักษณะเป็นแนวตั้งฉากกับกาบใบ การวางไข่ทำให้กาบใบเป็นรอยช้ำสีน้ำตาลมองเห็นได้ชัด ระยะไข่ใช้เวลา 7 วัน จะฟังเป็นตัวอ่อนและผ่านการลอกคราบ 5 ครั้งเป็นเวลา 16 วัน ก็จะเป็นตัวเต็มวัย ตัวเต็มวัยเพศเมียมีชีวิตอยู่ได้ 15 วัน เพศผู้อยู่ได้ 13วัน ตัวเมียวางไข่เฉลี่ย 48 ฟองต่อตัว การเพิ่มปริมาณของแมลงในนาข้าวจะมีปริมาณสูงสุดในชั่วอายุขัย ที่ 2 และ 3 ถ้าสภาพแวดล้อมเหมาะสมปริมาณจะเพิ่มขึ้นตามอายุข้าวจากระยะกล้าถึงระยะออกรวง ระยะตั้งท้องและออกรวง ระยะตั้งท้องและออกรวงมักเป็นระยะที่พบประชากรแมลงสูงที่สุดและอาการ hopper burn มักจะพบในระยะนี้<br />
                การกัดทำลาย: "ตัวเต็มวัยและตัวอ่อนจะดูดกินน้ำเลี้ยงที่กาบใบข้าวเหนือระดับน้ำเล็กน้อย เมื่อแมลงจำนวนมากดูดต้นข้าวจะทำให้ข้าวแสดงอาการใบเหลืองและแห้งตายลักษณะคล้ายถูกน้ำร้อนวก อาการต้นข้าวแห้งตายทั้งกอหรือแห้งตายเป็นหย่อมๆ ในแปลงนาเรียกว่า ""hopper burn"" นอกจากนี้ ยังเป็นแมลพาหนะนำโรคใบหงิกและโรคเขียวเตี้ยสู่ต้นข้าวอีกด้วย การแพร่ระบาดอาจเกิดขึ้นได้ทั้งนาปีนาปรัง โดยเฉพาะช่วงที่อากาศร้อนและมีความชื้นสูง "<br />
                การป้องกัน<br />
                1.ควรงดปลูกข้าวในฤดูนาปรัง เพื่อยับยั้งการขยายพันธุ์อย่างต่อเนื่องของเพลี้ยกระโดดสีน้ำตาล โดยปลูกพืชอื่นแทน เช่น ถั่วเขียว ถั่วเหลือง เป็นต้น<br />
                2. หากต้องการจะปลูกข้าวต่อไปในฤดูนาปรังและนาปี ขอให้ปลูกข้าวพันธุ์ที่มีความต้านทานต่อเพลี้ยกระโดดสำน้ำตาล เช่น พันธุ์ กข21 กข23 พิษณุโลก 60-2 สุพรรณบุรี 90 ชัยนาท 1 สุพรรณบุรี 1 สุพรรณบุรี 2 เหนียวแพร่ 1 พิษณุโลก 2 ปทุมธานี 1<br />
                3. ใช้กับดักแสงไฟชนิดหลอดเรืองแส (นีออน) ล่อและทำลายตัวเต็มวัของเพลี้ยกระโดดสีน้ำตาลตั้งแต่พลบค่ำถึงประมาณ 3 ทุ่ม ในช่วงการเก็บเกี่ยวข้าวจนถึงการปลูกข้าวในครั้งต่อไป เพื่อลดปริมาณเพลี้ยกระโดดสีน้ำตาลให้น้อยลง<br />
                4. เมื่อหว่านหรือปักดำข้าวแล้ว เกษตรกรต้องตรวจข้าวในนาอย่างสม่ำเสมอทุก 3-5 วัน และปฏิบัติดังนี้<br />
                - ในแปลงตกกล้า หากพบเพลี้ยกระโดดสีน้ำตาลเข้าทำลาย ควรไขน้ำเข้านาเพื่อไล่เพี้ยให้ขึ้นมาบนยอดข้าวและพ่นสารฆ่าแมลงกำจัดเสีย เมื่อข้าวนาหว่านมีอายุมากกว่า 6 สัปดาห์ หรือนาดำที่มีอายุประมาณ 3 สัปดาห์ เพื่อเปลี่ยนสภาพนาไม่ให้เหมาะแก่การเจริญเติบโตและการขยายพันธุ์ของเพลี้ยกระโดดสีน้ำตาล<br />
                - ในแปลงนา ถ้าพบเพลี้ยกระโดดสีน้ำตาลในนาหว่านน้ำตม ตั้งแต่ 1 ตัว/ต้น หรือในนาดำตั้งแต่ 10 ตัว/กอ ให้พ่นสารฆ่าแมลง หากพื้นที่นั้นเคยมีการระบาดของโรคจู๋มาก่อน ให้พ่นสารฆ่าแมลงทันทีเมื่อพบเพลี้ยกระโดสีน้ำตาล 1 ตัว/10 ต้น สำหรับนาหว่าน และ 1ตัว/กอ สำหรับนาดำ สารฆ่าแมลง ได้แก่ คารฺแทป/ไอโซโพรคาร์บ (3% / 3% จี ) ในแปลงกล้า ใช้อัตร 8-10 กิโลกรัมต่อไร่ ในแปลงปักดำ ใช้อัตรา 5 กิโลกรัมต่อไร่ ในนาหว่าน ใช้อัตรา 5 กิโลกรัมต่อไร่ ควรใช้ในระยะกล้าจนถึงระยะแตกกอ ระดับน้ำในนา 5 เซนติเมตร<br />
            </Typography>

            <Typography gutterBottom sx={{ fontSize: '14px', mb: 5 }}>
                ชื่อศัตรูข้าว: หนอนกระทู้กล้า<br />
                รูปร่างลักษณะ:  ผีเสื้อหนอนกระทู้กล้า  เป็นผีเสื้อกลางคืนปีกคู่หน้าสีเทาปนน้ำตาล  ความกว้างของปีกกลางออกประมาณ 3.5-4 เซนติเมตร ปีกคู่หลังสีขาว บินเก่งสามารถอพยพได้ไกลเป็นระยะทางหลายสิบ หรือหลายร้อยกิโลเมตร  วางไข่เป็นกลุ่มบริเวณยอดอ่อนของข้าว ตัวหนอนมีสีเทาถึงเขียวแกมดำ ด้านหลังมีลายตามความยาวของลำตัวจากหัวจรดท้าย  แต่ละปล้องมีจุดสีดำ<br />
                การขยายพันธุ์: ตัวหนอนฟักจากไข่ช่วงเช้าตรู่ และรวมกลุ่มกันกัดกินส่วนปลายใบข้าว กลางวันจะหลบอยู่ในดินใต้เศษใบพืช  ในพื้นนาที่แห้ง บางส่วนอยู่บนต้นข้าวส่วนที่อยู่เหนือน้ำในนาที่ลุ่ม  ชอบเข้าดักแด้ในดินหรือบนต้นหญ้าตามขอบแปลง ตัวหนอนโตเต็มที่ยาวประมาณ  3.5-4 มิลลิเมตร กว้าง 5-6 มิลลิเมตร  ชีพจักรจะแตกต่างกันตามพื้นที่ระบาด<br />
                การกัดทำลาย: หนอนจะทำลายข้าวในเวลากลางคืนหนอนระยะแรกจะกัดกินผิวข้าวเมื่อโตขึ้นจะกัดกินกัดกินทั้งใบ และต้นข้าวเหลือไว้แต่ก้านใบตัวหนอนจะกัดกินต้นกล้าระดับพื้นดินนาข้าวจะถูกทำลายแหว่งเป็นหย่อมๆและอาจเสียหายได้ภายใน 1-2 วัน    ความเสียหายเกิดขึ้นอย่างรวดเร็ว หนอนมีการเคลื่อนย้ายเป็นกลุ่มคล้ายกองทัพ จากการขยายพันธุ์หลายๆ รุ่นบนวัชพืชพวกหญ้า และเคลื่อนเข้าสู่แปลงกล้าและนาข้าวจากแปลงหนึ่งไปยังอีกแปลงหนึ่ง  มักพบระบาดในช่วงฤดูฝน โดยเฉพาะหลังจากผ่านช่วงแล้งที่ยาวนาน แล้วตามด้วยฝนตกหนัก การระบาดจะรุนแรงเป็นบางปี บางพื้นที่<br />
                การป้องกัน<br />
                1. กำจัดวัชพืชตามคันนาหรือบริเวณใกล้เคียงเพื่อทำลายแหล่งอาศัย<br />
                2. ใช้สารฆ่าแมลง มาลาไทออน (มาลาไธออน 83% อีซี) อัตรา 20 มิลลิลิตรต่อน้ำ 20 ลิตร หรือ เฟนิโตรไทออน (ซูมิไทออน 50% อีซี) อัตรา 30 มิลลิลิตรต่อน้ำ 20 ลิตร ฉีดพ่นเมื่อพบใบข้าวถูกทำลายมากกว่า 15 เปอร์เซ็นต์<br />
            </Typography>

        </div>
    )
}

export default Desc
