import * as React from 'react';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { GrassOutlined, HomeOutlined, AutoAwesomeOutlined, LocalGroceryStoreOutlined } from '@mui/icons-material/'
import { Paper, Typography } from '@mui/material';

const ResponsiveAppBar = ({ onValueChange }) => {
  const [value, setValue] = React.useState(0)
  const accessToken = window.localStorage.getItem('accessToken');

  const handleNaviBottomChange = (value) => {
    setValue(value);
    onValueChange(value);
    // if(value === 0){
    //   window.location.href = '/';
    // }
    if (value === 3) {
      if (accessToken) {
        window.location.href = '/rice_calendar/' + accessToken;
      } else {
        window.location.href = '/user/login';
      }
    }
  }
  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 9999, boxShadow: 2 }} elevation={3}>
      <BottomNavigation className="bottom-bar"
        showLabels
        sx={{
          background: '#129137',
          height: '4.5rem',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-evenly',

        }}
        value={value}
        onChange={(event, newValue) =>
          handleNaviBottomChange(newValue)
        }
      >
        <BottomNavigationAction label={<Typography style={{ fontFamily: "'IBM Plex Sans Thai', sans-serif", fontSize:'12px' }} variant="subtitle1" color="common.white"><b>หน้าแรก</b></Typography>} icon={<HomeOutlined sx={{ color: 'white' }} fontSize='large' />} />
        <BottomNavigationAction label={<Typography style={{ fontFamily: "'IBM Plex Sans Thai', sans-serif" , fontSize:'12px'}} variant="subtitle1" color="common.white"><b>รวมพยากรณ์</b></Typography>} icon={<AutoAwesomeOutlined sx={{ color: 'white' }} fontSize='large' />} />
        <BottomNavigationAction label={<Typography style={{ fontFamily: "'IBM Plex Sans Thai', sans-serif" , fontSize:'12px'}} variant="subtitle1" color="common.white"><b>ตลาดสินค้าข้าว</b></Typography>} icon={<LocalGroceryStoreOutlined sx={{ color: 'white' }} fontSize='large' />} />
        <BottomNavigationAction label={<Typography style={{ fontFamily: "'IBM Plex Sans Thai', sans-serif" , fontSize:'12px'}} variant="subtitle1" color="common.white"><b>ปฏิทินข้าว</b></Typography>} icon={<GrassOutlined sx={{ color: 'white' }} fontSize='large' />} />

      </BottomNavigation>
    </Paper>
  );
}
export default ResponsiveAppBar;