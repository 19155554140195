
import React, { useEffect } from 'react';
import Webheader from '../../components/web/header';

const RiceHere = () => {
    function getMobileOperatingSystem() {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            console.log("Windows Phone")
            return "Windows Phone";

        }

        if (/android/i.test(userAgent)) {
            console.log("android")
            return "Android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            console.log("ios")
            return "iOS";
        }
        console.log("xxx")
        return "unknown";
    }
    useEffect(() => {
        getMobileOperatingSystem()
    }, []);

    return (
        <div>
            <header
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    zIndex: 2,
                }}
            >
                <Webheader pageTitle="จับคู่เจรจาสินค้าข้าว" menu={2} />
            </header>
            <iframe
                title="Matching"
                src="https://play.google.com/store/apps/details?id=life.ufu.ricehere"
                style={{
                    position: 'fixed',
                    bottom: 0,
                    right: 0,
                    width: '100%',
                    border: 'none',
                    margin: 0,
                    padding: 0,
                    overflow: 'hidden',
                    zIndex: 1,
                    height: 'calc(100% - 80px)' // ลบความสูงของ header จากความสูงทั้งหน้าเพจ
                }}
            />
        </div>
    );
}

export default RiceHere
