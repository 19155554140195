import axios from "axios";
// import config from '../config.json';

export const adminLogin = async (obj) => {
    return axios.post('https://auth.ricethailand.go.th/authen/login', obj, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
