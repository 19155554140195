import React from 'react';
// import ReactDOM from 'react-dom/client';
import './index.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import KnowledgeRice from './pages/allrice/knowledge_rice';
import DroughtDisaster from './pages/allrice/drought_disaster';
import Bug from './pages/allrice/bug';

import SoilInfo from "./pages/allrice/soil_info"
import Home from './pages/allrice/home';
import PriceRice from './pages/allrice/price_rice';
import ForecastPrice from './pages/allrice/forecast_price';
import Irrigation from './pages/allrice/irrigation';
import ForecastIrrigation from './pages/allrice/forecast_irrigation'
import Weather from './pages/allrice/weather';
// import RiceCalender from './pages/allrice/rice_calendar'
import RiceCalenderMainPage from './pages/allrice/rice_calendar/main'
import RiceCalenderDetailPage from './pages/allrice/rice_calendar/detail'
import UserLogin from './pages/allrice/user_login'
import UserRegister from './pages/allrice/user_register'

//edit by l2ol3otic
import Matching from './pages/allrice/matching';
import RiceHere from './pages/allrice/ricehere';

import { ThemeProvider, createTheme } from '@mui/material';

const theme = createTheme({
  typography: {
    fontFamily: "'IBM Plex Sans Thai', sans-serif"
  },
});


function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/knowledge_rice" element={<KnowledgeRice />} />
          <Route path="/drought_disaster" element={<DroughtDisaster />} />
          <Route path="/bug" element={<Bug />} />

          <Route path="/soilinfo" element={<SoilInfo />} />
          <Route path="/priceRice" element={<PriceRice />} />
          <Route path="/forecastprice" element={<ForecastPrice />} />
          <Route path="/irrigation" element={<Irrigation />} />
          <Route path="/forecastirrigation" element={<ForecastIrrigation />} />
          <Route path="/weather" element={<Weather />} />
          {/* <Route path="/rice_calendar" element={<RiceCalender />} /> */}
          <Route path="/rice_calendar/:token" element={<RiceCalenderMainPage />} />
          <Route path="/rice_calendar/detail" element={<RiceCalenderDetailPage />} />
          <Route path="/user/login" element={<UserLogin />} />
          <Route path="/user/register" element={<UserRegister />} />

          {/* edit by l2ol3otic */}
          <Route path="/matching" element={<Matching />} />
          <Route path="/ricehere" element={<RiceHere />} />

        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;