import React, { useState, useEffect } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
    timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import { Button, Card, CardContent, Chip, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import BREED_DATA from './../../../data/rice_data.json'
import Dialog from '@mui/material/Dialog';
import Webheader from './../../../components/web/header'
import { useLocation, useNavigate } from 'react-router-dom';
import Desc from './model/desc_vermin';

export default function RiceCalenderDetailPage() {
    const navigate = useNavigate();
    const { search } = useLocation();

    const queryParams = new URLSearchParams(search);
    const typeRice = queryParams.get('typeRice');
    const id = queryParams.get('id');
    const method = queryParams.get('method');
    const strDate = queryParams.get('str_date');
    const sensitive = typeRice === '1' ? 'sensitive_light' : 'insensitive_light'

    const [dialog_title, setDialogTitle] = useState()
    const [dialog_sub_title, setDialogSubTitle] = useState()

    const textPhase = [
        'ก่อนเพาะปลูก',
        'ระยะกล้า',
        'ระยะแตกกอ',
        'ระยะตั้งท้อง',
        'ระยะน้ำนมและข้าวสุกแก่',
    ]

    const handleBack = () => {
        const accessToken = localStorage.getItem('accessToken')
        navigate(`/rice_calendar/${accessToken}`, { state: { method: method, sensitive: typeRice, breed: id } })
    }

    const handleClick = (title, phase) => {
        if (title === 'การสำรวจโรคแมลง') {
            setDialogTitle("โรค และแมลงศัตรูพืช")
            setDialogSubTitle(Desc)
        } else {
            setDialogTitle("คำแนะนำ " + phase)
            setDialogSubTitle(
                <Typography gutterBottom sx={{ fontSize: '14px', mb: 5 }}>
                    {BREED_DATA['type'][sensitive]['planting_description']['desc'][textPhase.indexOf(phase)].split('\n').map((line, index) => (
                        <React.Fragment key={index}>
                            {line}
                            <br />
                        </React.Fragment>
                    ))}
                </Typography>
            )
        }
        handleClickOpen()
    }

    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    // Convert the date string to the 'YYYY-MM-DD' format
    let date = new Date(strDate);
    let newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 30);

    // ดึงปีปัจจุบัน
    let currentYear = new Date().getFullYear();

    const min_pregnant = BREED_DATA['type'][sensitive].data[id - 1].min_pregnant;
    const min_bloom = BREED_DATA['type'][sensitive].data[id - 1].min_bloom;
    const min_harvest = BREED_DATA['type'][sensitive].data[id - 1].min_harvest;

    let min_pregnant_date = new Date(newDate)
    let min_bloom_date = new Date(newDate)
    let min_harvest_date = new Date(newDate)

    if (typeof (BREED_DATA['type'][sensitive].data[id - 1].min_pregnant) === 'number') {
        min_pregnant_date.setDate(newDate.getDate() + min_pregnant);
        min_bloom_date.setDate(newDate.getDate() + min_pregnant + min_bloom);
        min_harvest_date.setDate(newDate.getDate() + min_pregnant + min_bloom + min_harvest);
    } else {
        // แยกวันและเดือนจาก string
        const [min_pregnant_day, min_pregnant_month] = min_pregnant.split('-').map(Number);
        const [min_bloom_day, min_bloom_month] = min_bloom.split('-').map(Number);
        const [min_havest_day, min_havest_month] = min_harvest.split('-').map(Number);

        // สร้างวันที่ใหม่โดยใช้ปีปัจจุบัน, เดือน, และวันที
        min_pregnant_date = new Date(currentYear, min_pregnant_month - 1, min_pregnant_day);
        min_bloom_date = new Date(currentYear, min_bloom_month - 1, min_bloom_day);
        min_harvest_date = new Date(currentYear, min_havest_month - 1, min_havest_day);
    }

    // Increment the year by 1
    if (newDate > min_pregnant_date) {
        min_pregnant_date.setFullYear(currentYear + 1);
    }
    if (min_pregnant_date > min_bloom_date) {
        min_bloom_date.setFullYear(currentYear + 1);
    }
    if (min_bloom_date > min_harvest_date) {
        min_harvest_date.setFullYear(currentYear + 1);
    }

    const breedName = BREED_DATA['type'][sensitive].data[id - 1].name_short;
    const harvestDate = new Date(min_harvest_date);
    const formattedHarvestDate = harvestDate.toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' });

    const minDays = 90;
    const maxDays = 155;

    useEffect(() => {
        const isSuitableHarvestDate = (harvestDate) => {
            const startDate = new Date(date);

            const daysDifference = Math.floor((harvestDate - startDate) / (24 * 60 * 60 * 1000));

            // console.log('daysDifference', daysDifference)
            return daysDifference >= minDays && daysDifference <= maxDays;
        };

        if (!isSuitableHarvestDate(harvestDate)) {
            setOpen2(true)
            // alert(`วันที่ท่านเริ่มปลูกไม่เหมาะสม เนื่องจากพันธุ์ ${breedName} วันที่เก็บเกี่ยวตรงกับวันที่ ${formattedHarvestDate} วันที่เริ่มปลูก-เก็บเกี่ยวไม่ควรต่ำกว่า ${minDays} วัน และไม่เกิน ${maxDays} วัน`)

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const mapping = [
        {
            phase: textPhase[0],
            title: 'การปลูก',
            sub_title: method === '3' ? 'ตกหล้า' : 'หว่าน',
            date: date,
            background_color: '#149138',
            line_background_color: '#b7f06a',
            color: '#fff'
        },
        {
            phase: 'ระยะกล้า',
            title: 'การกำจัดวัชพืช',
            sub_title: 'กำจัดวัชพืช ครั้งที่ 1',
            date: new Date(date.getFullYear(), date.getMonth(), date.getDate() + 5),
            background_color: '#ffc108',
            line_background_color: '#b7f06a',
            color: '#fff'
        },
        {
            phase: 'ระยะกล้า',
            title: 'การสำรวจโรคแมลง',
            sub_title: 'สำรวจแมลง ครั้งที่ 1',
            date: new Date(newDate - (newDate - date) / 2),
            background_color: '#35ccff',
            line_background_color: '#b7f06a',
            color: '#fff'
        },

        {
            phase: 'ระยะแตกกอ',
            title: 'การปลูก',
            sub_title: method === '3' ? 'ปักดำ' : 'เริ่มแตกกอ',
            date: newDate,
            background_color: '#149138',
            line_background_color: '#81d836',
            color: '#fff'
        },
        {
            phase: 'ระยะแตกกอ',
            title: 'การใส่ปุ๋ย',
            sub_title: 'ใส่ปุ๋ยครั้งที่ 1',
            date: newDate,
            background_color: '#ff99ff',
            line_background_color: '#81d836',
            color: '#fff'
        },
        {
            phase: 'ระยะแตกกอ',
            title: 'การกำจัดวัชพืช',
            sub_title: 'กำจัดวัชพืชครั้งที่ 2',
            date: new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate() + 10),
            background_color: '#ffc108',
            line_background_color: '#81d836',
            color: '#fff'
        },
        {
            phase: 'ระยะแตกกอ',
            title: 'การสำรวจโรคแมลง',
            sub_title: 'สำรวจแมลง ครั้งที่ 2',
            date: new Date(min_pregnant_date - (min_pregnant_date - newDate) / 2),
            background_color: '#35ccff',
            line_background_color: '#81d836',
            color: '#fff'
        },

        {
            phase: 'ระยะตั้งท้อง',
            title: 'การปลูก',
            sub_title: 'เริ่มตั้งท้อง',
            date: min_pregnant_date,
            background_color: '#149138',
            line_background_color: '#ffcd00',
            color: '#fff'
        },
        {
            phase: 'ระยะตั้งท้อง',
            title: 'การกำจัดวัชพืช',
            sub_title: 'กำจัดวัชพืช ครั้งที่ 3',
            date: min_pregnant_date,
            background_color: '#ffc108',
            line_background_color: '#ffcd00',
            color: '#fff'
        },
        {
            phase: 'ระยะตั้งท้อง',
            title: 'การใส่ปุ๋ย',
            sub_title: 'ใส่ปุ๋ยครั้งที่ 2',
            date: min_pregnant_date,
            background_color: '#ff99ff',
            line_background_color: '#ffcd00',
            color: '#fff'
        },

        {
            phase: 'ระยะน้ำนมและข้าวสุกแก่',
            title: 'การปลูก',
            sub_title: 'เริ่มออกดอก',
            date: min_bloom_date,
            background_color: '#149138',
            line_background_color: '#cd6601',
            color: '#fff'
        },
        {
            phase: 'ระยะน้ำนมและข้าวสุกแก่',
            title: 'การสำรวจโรคแมลง',
            sub_title: 'สำรวจแมลงครั้งที่ 3',
            date: min_bloom_date,
            background_color: '#35ccff',
            line_background_color: '#cd6601',
            color: '#fff'
        },

        {
            phase: 'ระยะน้ำนมและข้าวสุกแก่',
            title: 'การปลูก',
            sub_title: 'เก็บเกี่ยว',
            date: min_harvest_date,
            background_color: '#149138',
            line_background_color: '#cd6601',
            color: '#fff'
        }
    ]

    return (
        <div>
            <header
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    zIndex: 2,
                }}
            >
                <Webheader pageTitle="ปฏิทินการปฏิบัติเพื่อการผลิตข้าว" menu={0} />
            </header>
            <div style={{ margin: '100px 0px 0px 0px' }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <p>
                        ข้าว {breedName} เมื่อปลูกวันที่ {new Date(strDate).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' })
                        }
                    </p>
                </div>
                <div>
                    <Timeline
                        sx={{
                            [`& .${timelineOppositeContentClasses.root}`]: {
                                flex: 0.1,
                            },
                            padding: '6px 0px 6px 16px'
                        }}
                    >
                        {mapping.map((item, index) => {
                            // const day = item.date.getDate();
                            // const monthIndex = item.date.getMonth();
                            // const year = item.date.getFullYear();

                            // // Array of month names
                            // const thaiMonthNames = [
                            //     'ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.',
                            //     'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.',
                            //     'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'
                            // ];

                            // Format the date
                            // const formattedDate = `${day} ${thaiMonthNames[monthIndex]} ${(year + 543) % 100}`;

                            const eventDate = new Date(item.date); // Assuming 'date' property in your 'item' object

                            // Format the date in Thai format
                            const formattedDate2 = eventDate.toLocaleDateString('th-TH', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric'
                            });

                            return (
                                <TimelineItem key={index}>
                                    <TimelineOppositeContent
                                        color="textSecondary"
                                        sx={{
                                            minWidth: '60px',
                                            fontSize: '14px',
                                            fontWeight: 'bold',
                                            display: 'flex',
                                            alignItems: 'center',
                                            textAlign: 'center',
                                            justifyContent: 'center'
                                        }}>
                                        {item.phase}
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineConnector sx={{ backgroundColor: item.line_background_color }} />
                                        <TimelineDot variant="outlined" sx={{ borderColor: item.line_background_color, margin: 0, padding: '5px', borderWidth: '5px' }} />
                                        <TimelineConnector sx={{ backgroundColor: item.line_background_color }} />
                                    </TimelineSeparator>
                                    <TimelineContent sx={{ mb: 2 }}>
                                        <Card sx={{ boxShadow: '0 0.25rem 1rem 0  rgba(47, 91, 234, 0.125)' }}>
                                            <CardContent>
                                                <Typography variant="h6" component="div" >
                                                    {formattedDate2}
                                                </Typography>
                                                <Chip label={item.title} sx={{ borderRadius: '10px', m: '15px 0px', backgroundColor: item.background_color, color: item.color }} />
                                                <Typography color="text.secondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    {item.sub_title}
                                                </Typography>
                                            </CardContent>
                                            <CardContent sx={{ padding: '0 16px' }}>
                                                <Chip label="ดูคำแนะนำ" variant="outlined" onClick={() => handleClick(item.title, item.phase)} />
                                            </CardContent>
                                        </Card>
                                    </TimelineContent>
                                </TimelineItem>
                            )
                        })}
                    </Timeline >
                </div>

                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {dialog_title}
                    </DialogTitle>
                    <DialogContent>
                        {dialog_sub_title}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>ปิด</Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={open2}
                    aria-labelledby="alert-dialog-title-2"
                    aria-describedby="alert-dialog-description-2"
                >
                    <DialogTitle id="alert-dialog-title">
                        คำแนะนำ ก่อนการปลูก
                    </DialogTitle>
                    <DialogContent>
                        วันที่ท่านเริ่มปลูก <span style={{ color: 'red' }}>ไม่เหมาะสม</span> เนื่องจากพันธุ์ {breedName} วันที่เก็บเกี่ยวตรงกับวันที่ {formattedHarvestDate} วันที่เริ่มปลูก-เก็บเกี่ยวไม่ควรต่ำกว่า {minDays} วัน และไม่เกิน {maxDays} วัน
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleBack()}>ย้อนกลับ</Button>
                    </DialogActions>
                </Dialog>
            </div>

        </div>
    );
}