import React, { useEffect, useState } from "react";
import LoadingScreen from "../../components/web/loading";
import Webheader from '../../components/web/header';
import { callService } from "../../services/allrice/rice_services";
import { CardContent, MenuItem, Select, Card, Button } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import UnhappyIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import HappyIcon from '@mui/icons-material/InsertEmoticon';
import { FormControl } from "@mui/material";
import { Box } from "@mui/material";

export default function ForecastWater() {
  const [isBusy, setBusy] = useState(true);
  const [prov_list, setProvList] = useState([]);
  const [selected_prov, setSelectedProv] = useState(0);
  const [amp_list, setAmpList] = useState([]);
  const [selected_amp, setSelectedAmp] = useState(0);
  const [tambon_list, setTambonList] = useState([]);
  const [selected_tambon, setSelectedTambon] = useState(0);
  const [water_list, setWaterList] = useState([]);

  const cardStyles = {
    minHeight: 100,
    minWidth: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  useEffect(() => {
    createProvinceList();
    setBusy(false);
  }, []);

  const handleSearchClick = () => {
    setBusy(true)
    const params = {
      prov: selected_prov,
      amp: selected_amp,
      tambon: selected_tambon
    };

    callService('getwater.php', { params: params })
      .then(res => {
        setBusy(false)
        const temp_list = res.data.map(item => ({
          "id_water_m": item.id_water_m,
          "prov": item.prov,
          "amp": item.amp,
          "tambon": item.tambon,
          "dam": item.dam,
          "river_basin": item.river_basin,
          "percen_area": item.percen_area,
          "irrigation_project": item.irrigation_project,
          "low_water": item.low_water,
          "JAN": item.JAN,
          "FEB": item.FEB,
          "MAR": item.MAR,
          "APR": item.APR,
          "MAY": item.MAY,
          "JUN": item.JUN,
          "JUL": item.JUL,
          "AUG": item.AUG,
          "SEP": item.SEP,
          "OCT": item.OCT,
          "NOV": item.NOV,
          "DEC": item.DEC,
        }));
        setWaterList(temp_list);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const createProvinceList = () => {
    callService('getProv_water.php')
      .then(res => {
        const temp_prov = res.data.map(item => ({
          "name": item.prov
        }));
        setProvList(temp_prov);
      });
  };

  const handleProvinceSelectChange = (event) => {
    const value = event.target.value;
    setSelectedProv(value);
    setSelectedAmp(0);
    setSelectedTambon(0);
    if (value !== 0) {
      const params = { prov: value };
      callService('getProv_water.php', { params: params })
        .then(res => {
          const amp_temp = res.data.map(item => ({
            "name": item.amp
          }));
          setAmpList(amp_temp);
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      setSelectedAmp(0);
    }
  };

  const handleAmphoeSelectChange = (event) => {
    const selected_value = event.target.value;
    setSelectedAmp(selected_value);
    setSelectedTambon(0);
    if (selected_value !== 0) {
      const params = {
        "prov": selected_prov,
        "amp": selected_value
      };
      callService('getProv_water.php', { params: params })
        .then(res => {
          const list_tambon = res.data.map(item => ({
            "name": item.tambon
          }));
          setTambonList(list_tambon);
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      setSelectedTambon(0);
    }
  };

  if (isBusy) {
    return <LoadingScreen />;
  }

  return (

    <>
      <div>
        <header
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            zIndex: 2,
          }}
        >
          <Webheader pageTitle="พยากรณ์น้ำ" menu={2} />
        </header>
        <div style={{ margin: '110px 10px' }}>
          <div style={{ margin: "0px 30px" }}>
            <FormControl fullWidth style={{ marginTop: '10px' }}>
              <Select
                id="select-prov"
                value={selected_prov}
                onChange={(event) => handleProvinceSelectChange(event)}
                style={{ fontFamily: "'IBM Plex Sans Thai', sans-serif", borderRadius: '10px' }}
              >
                <MenuItem value={0} disabled>
                  เลือกจังหวัด
                </MenuItem>
                {prov_list.map((item, index) => (
                  <MenuItem key={index} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth style={{ marginTop: '20px' }}>
              <Select
                id="select-amp"
                value={selected_amp}
                onChange={(event) => handleAmphoeSelectChange(event)}
                disabled={amp_list.length === 0 || selected_prov === 0}
                style={{ fontFamily: "'IBM Plex Sans Thai', sans-serif", borderRadius: '10px' }}
              >
                <MenuItem value={0} disabled>
                  เลือกอำเภอ
                </MenuItem>
                {amp_list.map((item, index) => (
                  <MenuItem key={index} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth style={{ marginTop: '20px' }}>
              <Select
                id="select-amp"
                value={selected_tambon}
                onChange={(event) => setSelectedTambon(event.target.value)}
                disabled={tambon_list.length === 0 || selected_amp === 0}
                style={{ fontFamily: "'IBM Plex Sans Thai', sans-serif", borderRadius: '10px' }}
              >
                <MenuItem value={0} disabled>
                  โปรดเลือกตำบล
                </MenuItem>
                {tambon_list.map((item, index) => (
                  <MenuItem key={index} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <Button
              variant="contained"
              color="primary"
              onClick={(event) => handleSearchClick(event)}
              disabled={!selected_prov || !selected_amp || !selected_tambon}
              startIcon={<SearchIcon />}
              style={{
                fontFamily: "'IBM Plex Sans Thai', sans-serif",
                borderRadius: '10px',
                marginTop: '20px',
                backgroundColor: (!selected_amp) ? '#bebebe' : '#129137',
                color: '#fff',
              }}
            >
              ค้นหา
            </Button>
          </div>
          <br />

          {water_list.map((item, index) => {
            return (
              <div style={{ margin: '20px 0' }} key={index}>
                <Card style={{ borderRadius: '15px' }}>
                  <CardContent>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                      <span>{item.dam}</span>
                    </div>
                    <div className="container">
                      <span>จังหวัด</span>
                      <span>{item ? item.prov : 'ไม่พบข้อมูล'}</span>
                    </div>
                    <div className="container">
                      <span>อำเภอ</span>
                      <span>{item ? item.amp : 'ไม่พบข้อมูล'}</span>
                    </div>
                    <div className="container">
                      <span>ตำบล</span>
                      <span>{item ? item.tambon : 'ไม่พบข้อมูล'} </span>
                    </div>
                    <div className="container">
                      <span>ลุ่มน้ำ</span>
                      <span>{item ? item.river_basin : 'ไม่พบข้อมูล'} </span>
                    </div>
                    <div className="container">
                      <span>ร้อยละของพื้น</span>
                      <span>{item ? item.percen_area : 'ไม่พบข้อมูล'} </span>
                    </div>
                    <div className="container">
                      <span>โครงการชลประทาน</span>
                      <span>{item ? item.irrigation_project : 'ไม่พบข้อมูล'} </span>
                    </div>
                    <div className="container">
                      <span>ฝนทิ้งช่วง</span>
                      <span>{item ? item.low_water : 'ไม่พบข้อมูล'} </span>
                    </div>

                    <div style={{ margin: 5 }}>
                      <Box
                        className="scrollbar-hidden"
                        sx={{
                          display: 'flex',
                          overflow: 'auto', // เปลี่ยนเป็น auto เพื่อให้สามารถเลื่อนได้
                          whiteSpace: 'nowrap',
                          flexWrap: 'nowrap', // เพิ่ม nowrap เพื่อป้องกันการขึ้นบรรทัดใหม่
                          '& > :not(style)': {
                            m: 1,
                            width: 100,
                            height: 100,
                            borderRadius: '10px',
                          },
                        }}
                      >
                        <Card sx={cardStyles} elevation={2}>
                          <div>
                            <p style={{ margin: '-5px 0 5px 0' }}>ม.ค.</p>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>{item.JAN === "1" ? (<HappyIcon style={{ fill: "green" }} />) : (<UnhappyIcon style={{ fill: "red" }} />)}</div>
                          </div>
                        </Card>
                        <Card sx={cardStyles} elevation={2}>
                          <div>
                            <p style={{ margin: '-5px 0 5px 0' }}>ก.พ.</p>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>{item.FEB === "1" ? (<HappyIcon style={{ fill: "green" }} />) : (<UnhappyIcon style={{ fill: "red" }} />)}</div>
                          </div>
                        </Card>
                        <Card sx={cardStyles} elevation={2}>
                          <div>
                            <p style={{ margin: '-5px 0 5px 0' }}>มี.ค.</p>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>{item.MAR === "1" ? (<HappyIcon style={{ fill: "green" }} />) : (<UnhappyIcon style={{ fill: "red" }} />)}</div>
                          </div>
                        </Card>
                        <Card sx={cardStyles} elevation={2}>
                          <div>
                            <p style={{ margin: '-5px 0 5px 0' }}>เม.ย.</p>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>{item.APR === "1" ? (<HappyIcon style={{ fill: "green" }} />) : (<UnhappyIcon style={{ fill: "red" }} />)}</div>
                          </div>
                        </Card>
                        <Card sx={cardStyles} elevation={2}>
                          <div>
                            <p style={{ margin: '-5px 0 5px 0' }}>พ.ค.</p>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>{item.MAY === "1" ? (<HappyIcon style={{ fill: "green" }} />) : (<UnhappyIcon style={{ fill: "red" }} />)}</div>
                          </div>
                        </Card>
                        <Card sx={cardStyles} elevation={2}>
                          <div>
                            <p style={{ margin: '-5px 0 5px 0' }}>ม.ย.</p>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>{item.JUN === "1" ? (<HappyIcon style={{ fill: "green" }} />) : (<UnhappyIcon style={{ fill: "red" }} />)}</div>
                          </div>
                        </Card>
                        <Card sx={cardStyles} elevation={2}>
                          <div>
                            <p style={{ margin: '-5px 0 5px 0' }}>ก.ค.</p>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>{item.JUL === "1" ? (<HappyIcon style={{ fill: "green" }} />) : (<UnhappyIcon style={{ fill: "red" }} />)}</div>
                          </div>
                        </Card>
                        <Card sx={cardStyles} elevation={2}>
                          <div>
                            <p style={{ margin: '-5px 0 5px 0' }}>ส.ค.</p>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>{item.AUG === "1" ? (<HappyIcon style={{ fill: "green" }} />) : (<UnhappyIcon style={{ fill: "red" }} />)}</div>
                          </div>
                        </Card>
                        <Card sx={cardStyles} elevation={2}>
                          <div>
                            <p style={{ margin: '-5px 0 5px 0' }}>ก.ย.</p>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>{item.SEP === "1" ? (<HappyIcon style={{ fill: "green" }} />) : (<UnhappyIcon style={{ fill: "red" }} />)}</div>
                          </div>
                        </Card>
                        <Card sx={cardStyles} elevation={2}>
                          <div>
                            <p style={{ margin: '-5px 0 5px 0' }}>ต.ค.</p>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>{item.OCT === "1" ? (<HappyIcon style={{ fill: "green" }} />) : (<UnhappyIcon style={{ fill: "red" }} />)}</div>
                          </div>
                        </Card>
                        <Card sx={cardStyles} elevation={2}>
                          <div>
                            <p style={{ margin: '-5px 0 5px 0' }}>พ.ย.</p>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>{item.NOV === "1" ? (<HappyIcon style={{ fill: "green" }} />) : (<UnhappyIcon style={{ fill: "red" }} />)}</div>
                          </div>
                        </Card>
                        <Card sx={cardStyles} elevation={2}>
                          <div>
                            <p style={{ margin: '-5px 0 5px 0' }}>ธ.ค.</p>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>{item.DEC === "1" ? (<HappyIcon style={{ fill: "green" }} />) : (<UnhappyIcon style={{ fill: "red" }} />)}</div>
                          </div>
                        </Card>
                      </Box>
                    </div>
                  </CardContent>
                </Card>
              </div>
            )
          })}
        </div>
      </div>
    </>
  );

}
