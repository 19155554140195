import React, { useEffect, useState } from "react";
import Webheader from './../../../components/web/header'
import { Button, Card, FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, Select } from "@mui/material";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import th from 'date-fns/locale/th'; // Import the Thai locale from date-fns
import BREED_DATA from './../../../data/rice_data.json'
import { useLocation, useParams } from "react-router-dom";
import jwt_decode from 'jwt-decode';
// import jwt from 'jsonwebtoken';

const RiceCalenderMainPage = () => {
    const { state } = useLocation();
    const { token } = useParams();

    var decoded = jwt_decode(token);
    console.log('decode', decoded)

    const [breedDropdown, setBreedDropdown] = useState([])
    const [data, setData] = useState({
        sensitive: state?.sensitive || 1,
        method: state?.method || 1,
        breed: state?.breed || 0
    })

    useEffect(() => {
        breedDropdownPerp('sensitive_light')
    }, [])

    const breedDropdownPerp = (light_type) => {
        setBreedDropdown(BREED_DATA['type'][light_type]['data'])
    }

    const handleChange = (e) => {
        const { name, value } = e.target

        if (name === "sensitive" && value === "1") {
            breedDropdownPerp('sensitive_light')
            setData({ ...data, breed: '0' })
        } else if (name === "sensitive" && value === "0") {
            breedDropdownPerp('insensitive_light')
            setData({ ...data, breed: '0' })
        }

        setData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    }

    const handleSubmit = () => {
        if (data.breed !== 0 && data.str_date !== undefined) {
            const inputDate = new Date(data.str_date);

            // Format the date as "YYYY-MM-DD"
            const formattedDate = `${inputDate.getFullYear()}-${(inputDate.getMonth() + 1).toString().padStart(2, '0')}-${inputDate.getDate().toString().padStart(2, '0')}`;
            window.location.href = `/rice_calendar/detail?typeRice=${data.sensitive}&id=${data.breed}&method=${data.method}&str_date=${formattedDate}`
        } else {
            alert("กรุณากรอกข้อมูลให้ครบถ้วน")
        }
    }

    if (breedDropdown.length === 0) return

    return (
        <>
            <div>
                <header
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                        zIndex: 2,
                    }}
                >
                    <Webheader pageTitle="ปฏิทินการปฏิบัติเพื่อการผลิตข้าว" menu={1} user={decoded && decoded.username} />
                </header>
                <div style={{ margin: '110px 20px' }}>
                    <Card sx={{ minWidth: 275 }}>
                        <div style={{ padding: '20px' }}>
                            <p>เลือกประเภทพันธุ์ข้าวที่ปลูก</p>
                            <FormControl>
                                <RadioGroup
                                    id="sensitive"
                                    name="sensitive"
                                    value={data.sensitive || 1}
                                    onChange={handleChange}
                                >
                                    <FormControlLabel value={1} control={<Radio />} label="ไวต่อช่วงแสง" />
                                    <FormControlLabel value={0} control={<Radio />} label="ไม่ไวต่อช่วงแสง" />
                                </RadioGroup>
                            </FormControl>

                            <p>พันธุ์ข้าวที่ปลูก</p>
                            <FormControl fullWidth>
                                <Select
                                    name="breed"
                                    id="breed"
                                    value={data.breed || 0}
                                    onChange={handleChange}
                                    sx={{ borderRadius: '10px' }}
                                >
                                    <MenuItem key={0} value={0} disabled>กรุณาเลือกพันธุ์</MenuItem>
                                    {
                                        breedDropdown.map((item, index) => {
                                            return (<MenuItem key={index + 1} value={index + 1}>{item.name_short}</MenuItem>);
                                        })
                                    }

                                </Select>
                            </FormControl>

                            <p>วิธีการปลูก</p>
                            <FormControl>
                                <RadioGroup
                                    name="method"
                                    id="method"
                                    value={data.method || 1}
                                    onChange={handleChange}
                                >
                                    <FormControlLabel value={1} control={<Radio />} label=" หว่าน/หยอด/โรยเมล็ด ข้าวแห้ง" />
                                    <FormControlLabel value={2} control={<Radio />} label="หว่านน้ำตม" />
                                    <FormControlLabel value={3} control={<Radio />} label="ปักดำ" />
                                </RadioGroup>
                            </FormControl>

                            <p>วันที่ปลูก</p>
                            <div className="customDatePickerWidth">
                                <DatePicker
                                    className="custom-date-picker"
                                    selected={data.str_date}
                                    onChange={event => setData({ ...data, str_date: event })}
                                    dateFormat="dd/MM/yyyy"
                                    showYearDropdown
                                    scrollableYearDropdown
                                    locale={th}
                                    placeholderText={'วันที่เริ่มต้น'}
                                />
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                <Button variant="contained" style={{ backgroundColor: 'orange' }} onClick={handleSubmit}>
                                    วางแผนการปลูก
                                </Button>
                            </div>
                        </div>
                    </Card>
                </div>
            </div >
        </>

    );

}

export default RiceCalenderMainPage;
