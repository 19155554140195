import axios from "axios";
import config from '../config.json';

export const addUser = async (obj) => {
    return axios.post(config.USER_API + '/users/create', obj, {
        headers: {
            'Content-Type': 'application/json',
        }
    })
}

export const addUserGroup = async (obj) => {
    return axios.post(config.USER_API + '/organizations/users/add', obj, {
        headers: {
            'Content-Type': 'application/json',
        }
    })
}

