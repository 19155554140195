import React, { useState, useEffect } from "react";
import { callService } from "../../services/allrice/rice_services";
import Webheader from "../../components/web/header";
import {
  Card,
  CardContent,
  Select,
  MenuItem,
  Button,
  FormControl,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import LoadingScreen from "../../components/web/loading";

export default function Irrigation() {
  const [prov_list, setProvList] = useState([]);
  const [selected_prov, setSelectedProv] = useState(0);
  const [amp_list, setAmpList] = useState([]);
  const [selected_amp, setSelectedAmp] = useState(0);
  const [dam_list, setDamList] = useState([]);
  const [isBusy, setBusy] = useState(true);

  useEffect(() => {
    setDamList([]);
    createProvinceList();
    setBusy(false);
  }, []);

  const createProvinceList = () => {
    callService("getProv.php")
      .then((res) => {
        let tempprov_list = res.data.map((item, index) => ({
          id: index + 1,
          name: item.province_name_th,
        }));
        setProvList(tempprov_list);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSearchClick = () => {
    setBusy(true)
    let params = {
      Prov: selected_prov,
      Amp: selected_amp,
    };

    callService("getDam.php", { params: params })
      .then((res) => {
        console.log('res', res)
        setBusy(false)
        let tempIrrList = res.data.map((item, index) => ({
          id: index,
          index: index + 1,
          dam_date: item.dam_date,
          dam_inflow: item.dam_inflow,
          dam_released: item.dam_released,
          dam_storage: item.dam_storage,
          storage_percent: item.storage_percent,
          dam_uses_water: item.dam_uses_water,
          dam_name: item.dam_name,
        }));
        setDamList(tempIrrList);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleProvinceSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedProv(selectedValue);
    setSelectedAmp(0);

    if (selectedValue !== "" || selectedValue !== 0) {
      let params = { id: selectedValue };
      callService("getProv.php", { params: params })
        .then((res) => {
          let tempamp_list = res.data.map((item, index) => ({
            id: index + 1,
            name: item.amphoe_name_th,
          }));
          setAmpList(tempamp_list);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setSelectedAmp(0);
    }
  };

  const handleAmpSelectChange = (event) => {
    setSelectedAmp(event.target.value);
  };

  if (isBusy) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <header
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          zIndex: 2,
        }}
      >
        <Webheader pageTitle="ปริมาณน้ำในอ่างเก็บน้ำ" menu={1} />
      </header>
      <div style={{ margin: '110px 10px' }}>
        <div style={{ margin: "0px 30px" }}>
          <FormControl fullWidth style={{ marginTop: '10px' }}>
            <Select
              id="select-prov"
              value={selected_prov}
              onChange={handleProvinceSelectChange}
              style={{ fontFamily: "'IBM Plex Sans Thai', sans-serif", borderRadius: '10px' }}
            >
              <MenuItem value={0} disabled>
                เลือกจังหวัด
              </MenuItem>
              {prov_list.map((item) => (
                <MenuItem key={item.id} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth style={{ marginTop: '20px' }}>
            <Select
              id="select-amp"
              value={selected_amp}
              onChange={handleAmpSelectChange}
              disabled={amp_list.length === 0 || selected_prov === 0}
              style={{ fontFamily: "'IBM Plex Sans Thai', sans-serif", borderRadius: '10px' }}
            >
              <MenuItem value={0} disabled>
                เลือกอำเภอ
              </MenuItem>
              {amp_list.map((item) => (
                <MenuItem key={item.id} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <Button
            variant="contained"
            color="primary"
            onClick={(event) => handleSearchClick(event)}
            disabled={!selected_prov || !selected_amp}
            startIcon={<SearchIcon />}
            style={{
              fontFamily: "'IBM Plex Sans Thai', sans-serif",
              borderRadius: '10px',
              marginTop: '20px',
              backgroundColor: (!selected_amp) ? '#bebebe' : '#129137',
              color: '#fff',
            }}
          >
            ค้นหา
          </Button>
        </div>
        <br />

        <div>
          <Card style={{ borderRadius: '15px' }}>
            <CardContent>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <span>{dam_list[0] ? dam_list[0]?.dam_name : 'กรุณาเลือก จังหวัด และอำเภอ ก่อน'}</span>
              </div>
              <div className="container">
                <span>วันที่</span>
                <span>{dam_list[0] ? dam_list[0]?.dam_date : 'ไม่พบข้อมูล'}</span>
              </div>
              <div className="container">
                <span>ปริมาตรน้ำไหลเข้า</span>
                <span>{dam_list[0] ? dam_list[0]?.dam_inflow : '0.0'} ล้าน ลบ.ม.</span>
              </div>
              <div className="container">
                <span>ปริมาตรน้ำไหลออก</span>
                <span>{dam_list[0] ? dam_list[0]?.dam_released : '0.0'} ล้าน ลบ.ม.</span>
              </div>
              <div className="container">
                <span>ปริมาตรน้ำกักเก็บ</span>
                <span>{dam_list[0] ? dam_list[0]?.dam_storage : '0.0'} ล้าน ลบ.ม.</span>
              </div>
              <div className="container">
                <span>เปอร์เซ็นต์ปริมาตรน้ำ</span>
                <span>{dam_list[0] ? dam_list[0]?.storage_percent : '0.0'}%</span>
              </div>
              <div className="container">
                <span>ปริมาตรการใช้น้ำ</span>
                <span>{dam_list[0] ? dam_list[0]?.dam_uses_water : '0.0'} ล้าน ลบ.ม.</span>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}
