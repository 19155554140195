import axios from 'axios';
import config from '../../config.json';
// import LocalStorage from '../../utils/LocalStorage';

export const callService = async (uri, obj) => {
  // let token = LocalStorage.getAccessToken();
  // console.log('obj', obj)
  return axios.get(
    config.AllRice_API + uri, obj,
    {
      headers: {
        'Content-Type': 'application/json',
      }
    }
  )
}

export const getLocation = async (latitude, longitude) => {
  const apiUrl = `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`;
  return axios.get(apiUrl)
}