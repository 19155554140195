import { CardContent, Button, Card, Select, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import Webheader from '../../components/web/header';
import { callService } from "../../services/allrice/rice_services";
import SearchIcon from '@mui/icons-material/Search';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import LoadingScreen from "../../components/web/loading";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import th from 'date-fns/locale/th';
import { TroubleshootRounded } from "@mui/icons-material";

export default function ForecastPrice() {

  useEffect(() => {
    createForecastList();
    createVarietieList();
    setBusy(false);
  }, []);

  const [var_list, setVarList] = useState([])
  const [forecast_list, setForecastList] = useState([])
  const [selected_var_ID, setSelectedVarID] = useState(0)
  const [selected_moisture_ID, setSelectedMoistureID] = useState(0)
  const [data, setData] = useState({
    str_date: null,
    to_date: null
  })
  const [isBusy, setBusy] = useState(true)

  const createVarietieList = () => {
    callService('getVarieties.php')
      .then(res => {
        console.log('res getVarieties: ', res)
        let temp_varietie_list = []
        let result = res.data
        result.map((item) => (
          temp_varietie_list.push({
            "id": item.vda_id,
            "name": item.vda_name_s
          })
        ))
        setVarList(temp_varietie_list);
      }).catch(error => {
        console.log(error)
      })
  }

  const moisture_list = [
    { "value": "15", "name": "ราคา ณ ความชื้น 15%" },
    { "value": "16", "name": "ราคา ณ ความชื้น 16%" },
    { "value": "17", "name": "ราคา ณ ความชื้น 17%" },
    { "value": "18", "name": "ราคา ณ ความชื้น 18%" },
    { "value": "19", "name": "ราคา ณ ความชื้น 19%" },
    { "value": "20", "name": "ราคา ณ ความชื้น 20%" },
    { "value": "21", "name": "ราคา ณ ความชื้น 21%" },
    { "value": "22", "name": "ราคา ณ ความชื้น 22%" },
    { "value": "23", "name": "ราคา ณ ความชื้น 23%" },
    { "value": "24", "name": "ราคา ณ ความชื้น 24%" },
    { "value": "25", "name": "ราคา ณ ความชื้น 25%" },
    { "value": "26", "name": "ราคา ณ ความชื้น 26%" },
    { "value": "27", "name": "ราคา ณ ความชื้น 27%" },
    { "value": "28", "name": "ราคา ณ ความชื้น 28%" },
    { "value": "29", "name": "ราคา ณ ความชื้น 29%" },
    { "value": "30", "name": "ราคา ณ ความชื้น 30%" },
  ]

  const handleSearchClick = () => {
    setBusy(TroubleshootRounded)
    let params = {
      "dateStart": data.str_date,
      "dateEnd": data.to_date,
      "varieties": selected_var_ID,
      "moisture": selected_moisture_ID,
    }

    console.log('params: ', params)
    callService('getDataForecastPrice.php', { params: params })
      .then(res => {
        setBusy(false)
        console.log('res getDataForecastPrice: ', res)
        let detail = res.data;
        let temp_forecast = []

        detail.map(item => (
          temp_forecast.push({
            "date": item.date,
            "dateTH": item.dateTH,
            "Month": item.Month,
            "price": item.price,
            "moisture": item.moisture,
            "type": item.type
          })
        ))

        setForecastList(temp_forecast);
      }).catch(error => {
        alert('เดือนเริ่มต้น หรือ เดือนสิ้นสุดไม่ถูกต้อง')
        console.log('err: ', error);
      })
  }

  const createForecastList = () => {
    callService('getDataForecastPrice.php')
      .then(res => {
        console.log('res getDataForecastPrice: ', res)

        let detail = res.data;
        let temp_forecast = [];

        detail.map((item) => (
          temp_forecast.push({
            "date": item.date,
            "dateTH": item.dateTH,
            "Month": item.Month,
            "price": item.price,
            "moisture": item.moisture,
            "type": item.type
          })
        ))
        setForecastList(temp_forecast);
      }).catch(error => {
        console.log(error.response);
      })
  }
  if (isBusy) return <LoadingScreen />

  return (
    <>
      <div>
        <header
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            zIndex: 2,
          }}
        >
          <Webheader pageTitle="พยากรณ์ราคาข้าวเปลือก" menu={2} />
        </header>
        <div style={{ margin: '110px 10px' }}>
          <div style={{ margin: "0px 30px" }}>
            <div className="customDatePickerWidth" style={{ marginTop: '20px' }}>
              <DatePicker
                className="custom-date-picker"
                selected={data.str_date}
                onChange={event => setData({ ...data, str_date: event })}
                showMonthYearPicker
                dateFormat="MM/yyyy"
                locale={th}
                placeholderText={'เดือนเริ่มต้น'}
                maxDate={data.to_date}
              />
            </div>

            <div className="customDatePickerWidth" style={{ marginTop: '20px' }}>
              <DatePicker
                className="custom-date-picker"
                selected={data.to_date}
                onChange={event => setData({ ...data, to_date: event })}
                showMonthYearPicker
                dateFormat="MM/yyyy"
                locale={th}
                placeholderText={'เดือนสิ้นสุด'}
                minDate={data.str_date}
              />
            </div>

            <div>
              <Select
                fullWidth
                id="select-product"
                value={selected_var_ID}
                onChange={event => setSelectedVarID(event.target.value)}
                style={{ fontFamily: "'IBM Plex Sans Thai', sans-serif", borderRadius: '10px', marginTop: '20px' }}
              >
                <MenuItem value={0} disabled>
                  เลือกพันธุ์ข้าว
                </MenuItem>
                {var_list.map((item, index) => {
                  return (<MenuItem key={index + 1} value={item.id}>{item.name}</MenuItem>)
                })}
              </Select>
            </div>

            <div>
              <Select
                fullWidth
                id="select"
                value={selected_moisture_ID}
                onChange={event => setSelectedMoistureID(event.target.value)}
                style={{ fontFamily: "'IBM Plex Sans Thai', sans-serif", borderRadius: '10px', marginTop: '20px' }}
              >
                <MenuItem key={0} value={0} disabled>
                  เลือกความชื้น
                </MenuItem>
                {moisture_list.map((item, index) => {
                  return (<MenuItem key={index + 1} value={item.value}>{item.name}</MenuItem>)
                })}
              </Select>
            </div>

            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <Button
                variant="contained"
                color="primary"
                onClick={(event) => handleSearchClick(event)}
                disabled={!data.str_date || !data.to_date || selected_var_ID === 0 || selected_moisture_ID === 0}
                startIcon={<SearchIcon />}
                style={{
                  fontFamily: "'IBM Plex Sans Thai', sans-serif",
                  borderRadius: '10px',
                  marginTop: '20px',
                  backgroundColor: (!data.str_date || !data.to_date || selected_var_ID === 0 || selected_moisture_ID === 0) ? '#bebebe' : '#129137',
                  color: '#fff',
                }}
              >
                ค้นหา
              </Button>
            </div>
          </div>
          <br />

          <div>
            <Card style={{ borderRadius: '15px' }}>
              <CardContent>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                  <span>{'กราฟพยากรณ์ราคาข้าวเปลือก'}</span>
                </div>

                <div>
                  <ResponsiveContainer width="100%" height={250}>
                    <LineChart data={forecast_list} margin={{ top: 20, right: 55, left: 20, bottom: 5 }}>
                      <CartesianGrid stroke="red" strokeDasharray="3 3" />
                      <XAxis dataKey="dateTH" axisLine={{ stroke: 'blue' }} />
                      <YAxis axisLine={{ stroke: 'blue' }} />
                      <Tooltip />
                      <Legend />
                      <Line type="monotone" dataKey="price" stroke="Green" activeDot={{ r: 8 }} />
                    </LineChart>
                  </ResponsiveContainer>
                </div>

              </CardContent>
            </Card>
          </div>

          {forecast_list.map((item, index) => {
            return (
              <div style={{ margin: '20px 0' }} key={index}>
                <Card style={{ borderRadius: '15px' }}>
                  <CardContent>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                      <span>{item.dateTH}</span>
                    </div>
                    <div className="container">
                      <span>ราคา</span>
                      <span>{item ? item.price : 'ไม่พบข้อมูล'}</span>
                    </div>
                    <div className="container">
                      <span>ราคา ณ ความชื้น</span>
                      <span>{item ? item.moisture + '%' : 'ไม่พบข้อมูล'}</span>
                    </div>
                    <div className="container">
                      <span>ประเภท</span>
                      <span>{item ? item.type : 'ไม่พบข้อมูล'} </span>
                    </div>
                  </CardContent>
                </Card>
              </div>
            )
          })}
        </div>
      </div>
    </>
  );
}