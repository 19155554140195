import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { IconButton, InputAdornment } from '@mui/material';
import Icon from 'react-icons-kit';
// import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { eye } from 'react-icons-kit/feather/eye'
import { eyeOff } from 'react-icons-kit/feather/eyeOff'
import * as authHttpServices from './../../services/auth-service'
import BG from './../../images/menu/rice-560051.jpg'
import { BiArrowBack } from "react-icons/bi";

const SignIn = () => {
    // const navigate = useNavigate();

    const [inputText, setInputText] = useState({
        username: "",
        password: ""
    });

    const [warnUsername, setWarnUsername] = useState(false);
    const [warnPassword, setWarnPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [icon, setIcon] = useState(eyeOff);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setInputText((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleToggle = () => {
        setIcon(showPassword ? eyeOff : eye);
        setShowPassword((prevState) => !prevState);
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        setWarnUsername(false);
        setWarnPassword(false);

        if (inputText.username === "") {
            setWarnUsername(true);
        }
        if (inputText.password === "") {
            setWarnPassword(true);
            alert('ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง')
        } else {
            const fetchAuth = async () => {
                try {
                    let obj = {
                        "username": inputText.username,
                        "password": inputText.password,
                        "app_id": Number(9),
                    }

                    const res = await authHttpServices.adminLogin(obj);
                    if (res.data.response.status) {
                        const accessToken = res.data.data[0].accessToken
                        console.log('accessToken', accessToken)
                        alert('เข้าสู่ระบบสำเร็จ')
                        localStorage.setItem('accessToken', accessToken)
                        // Diect page
                        window.location.href = `/rice_calendar/${accessToken}`
                    }
                } catch (error) {
                    // Handle any errors here
                    if (error.response) {
                        if (error.response.status === 400) {
                            alert('ป้อนชื่อผู้ใช้งานและรหัสผ่านของบัญชี ALLRICE ที่ได้ลงทะเบียนไว้')
                        } else if (error.response.status === 401) {
                            alert('ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง')
                        }
                    } else {
                        console.error(error);
                    }
                }
            };

            fetchAuth();
        }
    };

    return (
        <>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                backgroundImage: `url(${BG})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'bottom'
            }}>
                <BiArrowBack size={30} color={"gray"} style={{ position: 'absolute', top: 20, left: 20 }} onClick={() => window.history.back()} />
                <Box
                    sx={{
                        margin: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: 3,
                        borderRadius: '10px',
                        boxShadow: '0 0.25rem 1rem 0  rgba(47, 91, 234, 0.125)',
                        backgroundColor: 'white'
                    }}
                >
                    <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                        ยินดีต้อนรับ
                    </Typography>
                    <Typography sx={{ fontSize: '14px', mt: 1 }}>
                        ป้อนข้อมูลประจำตัวเพื่อเข้าถึงบัญชีของคุณ
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 4 }}>
                        <TextField
                            type="text"
                            className={warnUsername ? "warning" : ""}
                            placeholder="ป้อนชื่อผู้ใช้งาน"
                            value={inputText.username}
                            onChange={handleInputChange}
                            name="username"
                            autoComplete='off'
                            fullWidth
                            error={warnUsername}
                        />
                        <TextField
                            sx={{ mt: 2 }}
                            type={showPassword ? "text" : "password"}
                            placeholder="ป้อนรหัสผ่าน"
                            value={inputText.password}
                            onChange={handleInputChange}
                            name="password"
                            autoComplete='off'
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleToggle} edge="end">
                                            <Icon icon={icon} size={18} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            error={warnPassword}
                        />
                        <Button
                            style={{ backgroundColor: 'green' }}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            size="large"
                        >
                            เข้าสู่ระบบ
                        </Button>
                        <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
                            <p style={{ fontSize: '12px' }}>
                                ยังไม่มีบัญชีผู้ใช้? <Link href="/user/register">ลงทะเบียนทันที </Link>
                                {/* ยังไม่มีบัญชีผู้ใช้? <Link href="/user/register">ลงทะเบียนทันที </Link>ลืมรหัสผ่าน? <Link href="/user/login" style={{ color: 'red' }}>รีเซ็ตรหัสผ่าน</Link> */}
                            </p>
                        </Grid>
                    </Box>
                </Box>
            </div>
        </>
    );
}

export default SignIn