/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import Header from '../../components/web/header';
import { callService } from "../../services/allrice/rice_services";
import LoadingScreen from "../../components/web/loading";
import crosshair from './../../images/menu/crosshair_black.png'

//leaflet
import { MapContainer, TileLayer, useMapEvents, useMap, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css'; // Import the Leaflet CSS
import L, { latLng, map } from 'leaflet';

function Map() {
  const mapRef = useRef();
  const markerRef = useRef();
  const [detail, setDetail] = useState()
  const [isBusy, setBusy] = useState(true);
  const [initialPosition, setInitialPosition] = useState();

  const crosshairsIcon = new L.Icon({
    iconUrl: crosshair,
    iconSize: [32, 32],
    iconAnchor: [16, 16],
  });

  const MapEvents = () => {
    const map = useMap();
    let latLng

    useEffect(() => {
      const updateMarkerPosition = () => {
        const { lat, lng } = map.getCenter();
        latLng = [lat, lng]
        markerRef.current.setLatLng([lat, lng]);
      };

      map.on('move', updateMarkerPosition);
      updateMarkerPosition(); // Initial position update

      return () => {
        map.off('move', updateMarkerPosition);
      };
    }, []);

    const handleMapClick = async (e) => {
      setBusy(true)

      try {
        const { lat, lng } = markerRef.current._latlng

        const latLng = { latitude: lat, longitude: lng };
        const latLngString = JSON.stringify(latLng);
        localStorage.setItem("latlng", latLngString);

        const positionParams = {
          long: lat,
          lat: lng,
        };

        const soilData = await callService('getDataAllRice.php', { params: positionParams });
        console.log('soilData', soilData);

        setDetail(soilData)
        setBusy(false)
      } catch (error) {
        console.error('Error while fetching getDataAllRice:', error);
        alert('Error while fetching getDataAllRice')
      }
    };

    useMapEvents({
      click: handleMapClick,
    });

    return false;

  };

  // const MapEvents = () => {
  //   const handleMapClick = async (e) => {
  //     try {
  //       setBusy(true)
  //       console.log('lat', e.latlng.lat);
  //       console.log('long', e.latlng.lng);

  //       const latLng = { latitude: e.latlng.lat, longitude: e.latlng.lng };
  //       const latLngString = JSON.stringify(latLng);

  //       localStorage.setItem("latlng", latLngString);

  //       const positionParams = {
  //         long: e.latlng.lat,
  //         lat: e.latlng.lng,
  //       };

  //       const soilData = await callService('getDataAllRice.php', { params: positionParams });
  //       console.log('soilData', soilData);

  //       setDetail(soilData)
  //       setBusy(false)
  //     } catch (error) {
  //       console.error('Error while fetching daily weather data:', error);
  //       setBusy(false)
  //     }
  //   };

  //   useMapEvents({
  //     click: handleMapClick,
  //   });

  //   return false;
  // };

  useEffect(() => {
    var latlngString = localStorage.getItem("latlng");
    var latlng = JSON.parse(latlngString);

    const handleGeolocationSuccess = async (position) => {
      try {
        const { latitude, longitude } = position.coords;
        console.log('latitude, longitude', latitude, longitude)
        setInitialPosition([latitude, longitude])
        setBusy(false);
      } catch (error) {
        console.error(error);
      }
    };

    const handleGeolocationError = (error) => {
      console.error("Geolocation error:", error);
      alert("Geolocation is not supported by your browser");
      setInitialPosition([13.7367, 100.5231]) // center of thailand
      setBusy(false);
    };


    if (latlng) {
      const { latitude, longitude } = latlng
      setInitialPosition([latitude, longitude])
      setBusy(false)
    } else {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          handleGeolocationSuccess,
          handleGeolocationError
        );
      } else {
        console.error("Geolocation is not supported by your browser");
        alert("Geolocation is not supported by your browser");
      }
    }
  }, [map]);

  if (isBusy) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Header pageTitle="ข้อมูลชุดดิน" menu={1} />
      {detail ?
        <div>
          <div style={{ padding: 10 }}>
            <p style={{
              fontSize: 20,
              fontWeight: 'bold',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '10px 0 0 0'
            }}>
              {detail.data[0].num_SOILGROUP}
            </p>
            <p style={{ fontSize: 16, fontWeight: 'bold', margin: 5 }}>คุณสมบัติดิน</p>
            <p style={{ fontSize: 14, margin: 5 }}>&emsp;{detail.data[0].detail_Soil}</p>

            <p style={{ fontSize: 16, fontWeight: 'bold', margin: 5 }}>ปัญหา</p>
            <p style={{ fontSize: 14, margin: 5 }}>&emsp;{detail.data[0].problems_Soil}</p>

            <p style={{ fontSize: 16, fontWeight: 'bold', margin: 5 }}>การจัดการดิน</p>
            <p style={{ fontSize: 14, margin: 5 }}>&emsp;{detail.data[0].management_Soil}</p>
          </div>

          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <p style={{
              fontSize: 20,
              border: '1px solid gray',
              borderRadius: '5px',
              padding: '5px 15px',
            }}
              onClick={() => window.location.reload()}
            >
              กลับไปยังแผนที่
            </p>
          </div >
        </div>
        :

        <div>
          <div style={{ height: 50, padding: 5, backgroundColor: '#f4f4f4', textAlign: 'center' }}>
            <p style={{ fontSize: '14px' }}>กรุณาเลื่อนและกดตำแหน่งแผนที่ที่ต้องการทราบข้อมูลชุดดิน</p>
          </div>

          <div className="map-container">
            <MapContainer
              center={initialPosition}
              zoom={15}
              style={{ width: '100%', height: '79vh' }}
              ref={mapRef}
              zoomControl={false}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <MapEvents />
              <Marker position={initialPosition} icon={crosshairsIcon} ref={markerRef} zIndexOffset={1000} />

            </MapContainer>
          </div>
        </div>
      }
    </>
  );
}

export default Map;