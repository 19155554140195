import React, { useEffect, useState } from "react";
import Webheader from '../../components/web/header';
import { callService } from "../../services/allrice/rice_services";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Button, MenuItem, Select } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import LoadingScreen from "../../components/web/loading";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import th from 'date-fns/locale/th'; // Import the Thai locale from date-fns

const PriceRice = () => {
  const [rice_price_list, setRicePriceList] = useState([]);
  const [data, setData] = useState({
    str_date: null,
    to_date: null,
  })
  const [selected_product_id, setSelectedProductID] = useState(0)
  const [isBusy, setBusy] = useState(true);

  useEffect(() => {
    createPriceList();
  }, []);

  const handleSearchClick = () => {
    setBusy(true)
    let params = {
      "dateStart": data.str_date,
      "dateEnd": data.to_date,
      "productId": selected_product_id
    }
    console.log('params', params);

    callService('getPriceData.php', { params: params })
      .then(res => {
        setBusy(false)
        console.log('res: ', res)
        let detail = res.data;
        let temp_list = []

        detail.map((item, index) => (
          temp_list.push({
            id: index,
            date: item.date,
            product_name: item.product_name,
            price_min: item.price_min,
            price_max: item.price_max,
          })
        ))

        setRicePriceList(temp_list);
        //setBusy(false)
      }).catch(error => {
        console.log('err: ', error.response);
      })
  }
  const list_product_id = [
    { "id": "R13001", "name": "ข้าวสารเจ้า 100% ข้าวหอม ร้านค้าทั่วไป" },
    { "id": "R13003", "name": "ข้าวสารเจ้า 100% ธรรมดา ร้านค้าทั่วไป" },
    { "id": "R13004", "name": "ข้าวสารเจ้า 100% ธรรมดา (ข้าวใหม่)" },
    { "id": "R13005", "name": "ข้าวสารเจ้า 5% ร้านค้าทั่วไป" },
    { "id": "R13006", "name": "ข้าวสารเจ้า 5% (ข้าวใหม่)" },
    { "id": "R13007", "name": "ข้าวสารเหนียว สันป่าตอง (เขี้ยวงู) 100%" },
    { "id": "R13008", "name": "ข้าวสารเหนียว สันป่าตอง (เขี้ยวงู) 5%" },
    { "id": "R13009", "name": "ข้าวสารเหนียว สันป่าตอง (เขี้ยวงู) 10% เมล็ดยาว" },
    { "id": "R13010", "name": "ข้าวสารเหนียว สันป่าตอง (เขี้ยวงู) 10% เมล็ดสั้น" }
  ]

  const createPriceList = () => {
    callService('getPriceData.php')
      .then(res => {
        let temp_price = []
        temp_price = res.data.map((item, index) => ({
          id: index,
          date: item.date,
          price_min: item.price_min,
          price_max: item.price_max,
          flipped: false
        }));
        setRicePriceList(temp_price);
        setBusy(false)
      })
      .catch(error => {
        console.log('err', error.response);
      });
  }

  const preprocessData = (data) => {
    return data.map(item => ({
      date: item.date,
      price_min: parseFloat(item.price_min.split(' ')[0]),
      price_max: parseFloat(item.price_max.split(' ')[0])
    }));
  }

  const processedData = preprocessData(rice_price_list);

  if (isBusy) return <LoadingScreen />

  return (
    <>
      <div>
        <header
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            zIndex: 2,
          }}
        >
          <Webheader pageTitle="ราคาข้าวจากกรมการค้าภายใน" menu={1} />
        </header>
        <div style={{ margin: '110px 10px' }}>
          <div style={{ margin: "0px 30px" }}>
            <div className="customDatePickerWidth" style={{ marginTop: '20px' }}>
              <DatePicker
                className="custom-date-picker"
                selected={data.str_date}
                onChange={event => setData({ ...data, str_date: event })}
                dateFormat="dd/MM/yyyy"
                showYearDropdown
                scrollableYearDropdown
                locale={th}
                placeholderText={'วันที่เริ่มต้น'}
                maxDate={new Date()}
              />
            </div>

            <div className="customDatePickerWidth" style={{ marginTop: '20px' }}>
              <DatePicker
                className="custom-date-picker"
                selected={data.to_date}
                onChange={event => setData({ ...data, to_date: event })}
                dateFormat="dd/MM/yyyy"
                showYearDropdown
                scrollableYearDropdown
                locale={th}
                placeholderText={'วันที่เริ่มต้น'}
                maxDate={new Date()}
                minDate={data.str_date}
              />
            </div>

            <Select
              fullWidth
              id="select-product"
              value={selected_product_id}
              onChange={event => setSelectedProductID(event.target.value)}
              style={{ fontFamily: "'IBM Plex Sans Thai', sans-serif", borderRadius: '10px', marginTop: '20px' }}
            >
              <MenuItem value={0} disabled>
                เลือกผลิตภัณฑ์
              </MenuItem>
              {list_product_id.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>

            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <Button
                variant="contained"
                color="primary"
                onClick={(event) => handleSearchClick(event)}
                disabled={!data.str_date || !data.to_date}
                startIcon={<SearchIcon />}
                style={{
                  fontFamily: "'IBM Plex Sans Thai', sans-serif",
                  borderRadius: '10px',
                  marginTop: '20px',
                  backgroundColor: (!data.str_date || !data.to_date || !selected_product_id) ? '#bebebe' : '#129137',

                  color: '#fff',
                }}
              >
                ค้นหา
              </Button>
            </div>
          </div>
          <br />

          <div>
            <Card style={{ borderRadius: '15px' }}>
              <CardContent>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                  <span>{'กราฟแสดงข้อมูลราคาข้าว'}</span>
                </div>

                <div>
                  <ResponsiveContainer width="100%" height={250}>
                    <BarChart data={processedData} margin={{ top: 20, right: 55, left: 0, bottom: 0 }}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="price_min" fill="#2ecc71" name="ราคาต่ำสุด" />
                      <Bar dataKey="price_max" fill="#e74c3c" name="ราคาสูงสุด" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>

              </CardContent>
            </Card>
          </div>

          {rice_price_list.map((item, index) => {
            return (
              <div style={{ margin: '20px 0' }} key={index}>
                <Card style={{ borderRadius: '15px' }}>
                  <CardContent>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                      <span>{item ? '' : 'กรุณาเลือก จังหวัด และอำเภอ ก่อน'}</span>
                    </div>
                    <div className="container">
                      <span>วันที่</span>
                      <span>{item ? item.date : 'ไม่พบข้อมูล'}</span>
                    </div>
                    <div className="container">
                      <span>ราคาสูงสุด</span>
                      <span style={{ color: 'green' }}>{item ? item.price_max : 'ไม่พบข้อมูล'}</span>
                    </div>
                    <div className="container">
                      <span>ราคาต่ำสุด</span>
                      <span style={{ color: 'red' }}>{item ? item.price_min : 'ไม่พบข้อมูล'} </span>
                    </div>
                  </CardContent>
                </Card>
              </div>
            )
          })}
        </div>
      </div >
    </>

  );

}

export default PriceRice;
