import React, { useEffect, useState } from 'react';
import Webheader from "../../components/web/header";
import { Box, CardContent, Card, Divider } from "@mui/material"
import { callService, getLocation } from '../../services/allrice/rice_services';
import LoadingScreen from "../../components/web/loading";

function Weather() {
  const [isBusy, setBusy] = useState(true);
  const [weatherListH, setWeatherListH] = useState([])
  const [weatherListD, setWeatherListD] = useState([])
  const [address, setAddress] = useState()

  const cardStyles = {
    minHeight: 150,
    minWidth: 150,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const imageContainerStyles = {
    backgroundColor: '#f0f0f0',
    borderRadius: '50%',
    width: '65px',
    height: '65px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const imageStyles = {
    width: '60px',
    height: '60px',
  };

  useEffect(() => {
    const handleGeolocationSuccess = async (position) => {
      try {
        const { latitude, longitude } = position.coords;
        // console.log(latitude, longitude);

        // Fetch data from getLocation
        const api2Response = await getLocation(latitude, longitude);
        setAddress(api2Response.data);

        // Fetch hourly weather data
        const hourlyWeatherParams = {
          lat: latitude,
          long: longitude,
          type: 'H',
        };
        const hourlyWeatherData = await callService('getWeatherData.php', { params: hourlyWeatherParams });
        setWeatherListH(hourlyWeatherData);

        // Fetch daily weather data
        const dailyWeatherParams = {
          lat: latitude,
          long: longitude,
          type: 'D',
        };
        const dailyWeatherData = await callService('getWeatherData.php', { params: dailyWeatherParams });
        setWeatherListD(dailyWeatherData);

        // Set busy to false when all requests are completed
        setBusy(false);
      } catch (error) {
        console.error(error);
      }
    };

    const handleGeolocationError = (error) => {
      console.error("Geolocation error:", error);
      alert("Geolocation is not supported by your browser");
    };

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        handleGeolocationSuccess,
        handleGeolocationError
      );
    } else {
      console.error("Geolocation is not supported by your browser");
      alert("Geolocation is not supported by your browser");
    }
  }, []);

  function importAll(r) {
    const images = {};

    // Iterate over the keys in the context and map them to the images object
    r.keys().forEach((item) => {
      const key = item.replace('./', ''); // Remove the './' prefix
      const value = r(item); // Get the image using r(item)
      images[key] = value;
    });

    return images;
  }

  const images = importAll(require.context('./../../images/menu'));

  if (isBusy) {
    return <LoadingScreen />;
  }

  return (
    <>
      <header
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          zIndex: 2,
        }}
      >
        <Webheader pageTitle="พยากรณ์อากาศ" menu={2} />
      </header>
      <div style={{ margin: '80px 10px' }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <p style={{ fontSize: 20, margin: 20 }}>{address.address?.suburb} {address.address?.state}</p>
          <div style={{
            backgroundColor: '#f0f0f0',
            borderRadius: '50%',
            width: '125px',
            height: '125px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <img src={images[weatherListD?.data[0].cond_pic]} alt='' style={{ width: '120px', height: '120px', }} />
          </div>
          <p style={{ fontSize: 24, margin: '10px 0px 0px 0px' }}>{weatherListH.data[0].tc}°</p>
          <p style={{ fontSize: 22, margin: 5 }}>{weatherListH.data[0].cond_text}</p>
          <p style={{ fontSize: 20, margin: 5 }}>ต่ำสุด : {weatherListD?.data[0].tc_min}°&nbsp;&nbsp;&nbsp;&nbsp;สูงสุด : {weatherListD?.data[0].tc_max}°</p>
        </div>

        <div style={{ margin: 5 }}>
          <Box
            className="scrollbar-hidden"
            sx={{
              display: 'flex',
              overflow: 'auto', // เปลี่ยนเป็น auto เพื่อให้สามารถเลื่อนได้
              whiteSpace: 'nowrap',
              flexWrap: 'nowrap', // เพิ่ม nowrap เพื่อป้องกันการขึ้นบรรทัดใหม่
              '& > :not(style)': {
                m: 1,
                width: 128,
                height: 128,
                borderRadius: '10px',
              },
            }}
          >
            {weatherListH.data?.map((item, index) => {
              return (
                <Card sx={cardStyles} elevation={2} key={index}>
                  <CardContent style={{ textAlign: 'center', padding: '10px' }}>
                    <p style={{ fontSize: 18, margin: 5 }}>
                      {item.hour}
                    </p>
                    <div style={imageContainerStyles}>
                      <img src={images[item.cond_pic]} alt='' style={imageStyles} />
                    </div>
                    <p style={{ fontSize: 18, margin: 5 }}>
                      {item.tc}°
                    </p>
                  </CardContent>
                </Card>
              )
            })}
          </Box>
        </div>

        <p style={{ fontSize: 20, margin: '10px 0px', paddingLeft: 10, color: '#737475' }}>
          พยากรณ์อากาศ 7 วัน
        </p>

        {weatherListD.data?.map((item, index) => {
          return (
            <div key={index}>
              <div style={{ display: 'flex', padding: 15 }}>
                <div style={imageContainerStyles}>
                  <img src={images[item.cond_pic]} alt='' style={imageStyles} />
                </div>

                <div style={{ paddingLeft: 15 }}>
                  <p style={{ fontSize: 18, margin: 5 }}>ต่ำสุด : {item.tc_min}°&emsp;สูงสุด : {item.tc_max}°</p>
                  <p style={{ fontSize: 18, margin: 5 }}>{item.date}</p>
                </div>
              </div>

              <Divider />
            </div>
          )
        })}
      </div>
    </>
  );
}

export default Weather;
